import { gql } from "@apollo/client";

export const loginRootMutation = gql`
  query MyQuery(
    $verify_token: String = ""
    $recaptcha_code: String = ""
    $email: String = ""
  ) {
    iam_v1_GetAuthToken(
      input: {
        email: $email
        recaptcha_code: $recaptcha_code
        verify_token: $verify_token
      }
    ) {
      auth_token
      cloudfront_key_pair_id
      cloudfront_signature
      cloudfront_policy
    }
  }
`;

export const sendLoginCodeMut = gql`
  mutation MyMutation($email: String = "", $recaptcha_code: String = "") {
    iam_v1_SendLoginCode(
      input: { email: $email, recaptcha_code: $recaptcha_code }
    )
  }
`;

export const sendLoginCodeIAMMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $recaptcha_code: String = ""
    $username: String = ""
  ) {
    iam_v1_SendLoginCode(
      input: {
        account_id: $account_id
        username: $username
        recaptcha_code: $recaptcha_code
      }
    )
  }
`;

export const loginSSOMut = gql`
  mutation MyMutation($code: String = "", $state: String = "") {
  iam_v1_LoginSSO(input: {code: $code, state: $state}) {
    user {
      access_type
      account_id
      account_type
      antiphish_token
      billing_status
      created_at
      email
      email_verified
      first_name
      last_name
      permissions
      updated_at
      user_type
      username
    }
    auth_token
  }
}
`;

export const iamLoginRootMutation = gql`
  query MyQuery(
    $verify_token: String = ""
    $username: String = ""
    $recaptcha_code: String = ""
    $account_id: String = ""
  ) {
    iam_v1_GetAuthToken(
      input: {
        account_id: $account_id
        recaptcha_code: $recaptcha_code
        username: $username
        verify_token: $verify_token
      }
    ) {
      auth_token
    }
  }
`;

export const logoutMutation = gql`
  mutation MyMutation {
    authn_v1_Logout
  }
`;

export const userProfileMutation = gql`
  query MyQuery {
    iam_v1_GetAuthInfo {
      user {
        account_id
        account_type
        created_at
        email
        first_name
        last_name
        permissions
        billing_status
        updated_at
        user_type
        username
      }
    }
  }
`;

export const sendConfirmCodeMut = gql`
  mutation MyMutation(
    $type: api_iam_v1_AccountType = ACCOUNT_TYPE_PERSONAL_UNSPECIFIED
    $recaptcha_code: String = ""
    $email: String = ""
  ) {
    iam_v1_SendSignUpCode(
      input: { recaptcha_code: $recaptcha_code, type: $type, email: $email }
    )
  }
`;

export const checkConfirmCodeMut = gql`
  mutation MyMutation(
    $email: String = ""
    $recaptcha_code: String = ""
    $verify_token: String = ""
  ) {
    iam_v1_VerifySignUpCode(
      input: {
        email: $email
        recaptcha_code: $recaptcha_code
        verify_token: $verify_token
      }
    ) {
      status
    }
  }
`;

export const signUpMut = gql`
  mutation MyMutation(
    $address: api_iam_v1_Address_Input = {}
    $type: api_iam_v1_AccountType = ACCOUNT_TYPE_PERSONAL_UNSPECIFIED
    $recaptcha_code: String = ""
    $last_name: String = ""
    $first_name: String = ""
    $email: String = ""
    $verify_token: String = ""
  ) {
    iam_v1_CreateRootUser(
      input: {
        type: $type
        recaptcha_code: $recaptcha_code
        last_name: $last_name
        first_name: $first_name
        email: $email
        address: $address
        verify_token: $verify_token
      }
    ) {
      auth_token
      user {
        access_type
        account_id
        account_type
        antiphish_token
        billing_status
        created_at
        email
        email_verified
        first_name
        last_name
        permissions
        updated_at
        user_type
        username
      }
    }
  }
`;

export const getStripeSessionQuery = gql`
  query MyQuery {
    billing_v1_GetUpgradeStripeSession {
      redirect_url
    }
  }
`;
