import { gql } from "@apollo/client";

export const dashboardQuery = gql`
  query MyQuery($account_id: String = "") {
    iam_v1_GetUsage(input: { account_id: $account_id }) {
      account_id
      group_count
      policy_count
      user_count
      user_keys_count
    }
  }
`;

export const usersQuery = gql`
  query MyQuery($account_id: String = "") {
    iam_v1_ListUsers(input: { account_id: $account_id }) {
      users {
        email_verified
        access_type
        account_id
        account_type
        antiphish_token
        billing_status
        created_at
        email
        first_name
        last_name
        permissions
        updated_at
        user_type
        username
      }
      last_evaluated_user {
        gsi1_pk
        gsi1_sk
        pk
        sk
      }
    }
  }
`;

export const userByLastEvaluatedUserQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $last_evaluated_user: api_iam_v1_LastEvaluated_Input = {}
  ) {
    iam_v1_ListUsers(
      input: {
        account_id: $account_id
        last_evaluated_user: $last_evaluated_user
      }
    ) {
      users {
        account_id
        account_type
        antiphish_token
        billing_status
        created_at
        email
        first_name
        last_name
        permissions
        updated_at
        user_type
        username
      }
      last_evaluated_user {
        gsi1_pk
        gsi1_sk
        pk
        sk
      }
    }
  }
`;

export const createUserMut = gql`
  mutation MyMutation(
    $access_type: api_iam_v1_UserAccessType = USER_ACCESS_TYPE_MANAGEMENT_UNSPECIFIED
    $account_id: String = ""
    $email: String = ""
    $first_name: String = ""
    $last_name: String = ""
    $username: String = ""
  ) {
    iam_v1_CreateUser(
      input: {
        access_type: $access_type
        account_id: $account_id
        email: $email
        first_name: $first_name
        last_name: $last_name
        username: $username
      }
    ) {
      user {
        account_id
        account_type
        antiphish_token
        billing_status
        created_at
        email
        first_name
        last_name
        permissions
        updated_at
        user_type
        username
      }
    }
  }
`;

export const groupsQuery = gql`
  query MyQuery($account_id: String = "") {
    iam_v1_ListGroups(input: { account_id: $account_id }) {
      groups {
        account_id
        created_at
        description
        group_name
        group_type
        permissions
        policy_name
        updated_at
      }
      last_evaluated_group {
        gsi1_pk
        gsi1_sk
        pk
        sk
      }
    }
  }
`;

export const groupsByLastEvalutaredGroupQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $last_evaluated_group: api_iam_v1_LastEvaluated_Input = {}
  ) {
    iam_v1_ListGroups(
      input: {
        account_id: $account_id
        last_evaluated_group: $last_evaluated_group
      }
    ) {
      groups {
        account_id
        created_at
        description
        group_name
        group_type
        permissions
        policy_name
        updated_at
      }
      last_evaluated_group {
        gsi1_pk
        gsi1_sk
        pk
        sk
      }
    }
  }
`;

export const createGroupMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $description: String = ""
    $group_name: String = ""
  ) {
    iam_v1_CreateGroup(
      input: {
        account_id: $account_id
        description: $description
        group_name: $group_name
      }
    ) {
      group {
        account_id
        created_at
        description
        group_name
        group_type
        permissions
        policy_name
        updated_at
      }
    }
  }
`;

export const addUserToGroupMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $group_name: String = ""
    $username: String = ""
  ) {
    iam_v1_AddUserToGroup(
      input: {
        account_id: $account_id
        group_name: $group_name
        username: $username
      }
    ) {
      status
    }
  }
`;

export const removeUserFromGroupMut = gql`
  mutation MyMutation(
    $username: String = ""
    $group_name: String = ""
    $account_id: String = ""
  ) {
    iam_v1_RemoveUserFromGroup(
      input: {
        account_id: $account_id
        group_name: $group_name
        username: $username
      }
    ) {
      status
    }
  }
`;

export const deleteUserMut = gql`
  mutation MyMutation($account_id: String = "", $username: String = "") {
    iam_v1_DeleteUser(input: { account_id: $account_id, username: $username }) {
      status
    }
  }
`;

export const deleteGroupMut = gql`
  mutation MyMutation($group_name: String = "", $account_id: String = "") {
    iam_v1_DeleteGroup(
      input: { account_id: $account_id, group_name: $group_name }
    ) {
      status
    }
  }
`;

export const policiesQuery = gql`
  query MyQuery($account_id: String = "") {
    iam_v1_ListPolicies(input: { account_id: $account_id }) {
      last_evaluated_policy {
        gsi1_pk
        gsi1_sk
        pk
        sk
      }
      policies {
        account_id
        created_at
        description
        permissions
        policy_name
        policy_type
        updated_at
      }
    }
  }
`;

export const policiesByLastEvalutaedPolicyQuery = gql`
  query MyQuery($account_id: String = "") {
    iam_v1_ListPolicies(input: { account_id: $account_id }) {
      last_evaluated_policy {
        gsi1_pk
        gsi1_sk
        pk
        sk
      }
      policies {
        account_id
        created_at
        description
        permissions
        policy_name
        policy_type
        updated_at
      }
    }
  }
`;

export const createPolicyMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $description: String = ""
    $permissions: [String] = ""
    $policy_name: String = ""
  ) {
    iam_v1_CreatePolicy(
      input: {
        account_id: $account_id
        description: $description
        permissions: $permissions
        policy_name: $policy_name
      }
    ) {
      policy {
        account_id
        created_at
        description
        permissions
        policy_name
        policy_type
        updated_at
      }
    }
  }
`;

export const deletePolicyMut = gql`
  mutation MyMutation($account_id: String = "", $policy_name: String = "") {
    iam_v1_DeletePolicy(
      input: { account_id: $account_id, policy_name: $policy_name }
    ) {
      status
    }
  }
`;

export const addPolicyToGroupMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $group_name: String = ""
    $policy_name: String = ""
  ) {
    iam_v1_AddPolicyToGroup(
      input: {
        group_name: $group_name
        policy_name: $policy_name
        account_id: $account_id
      }
    ) {
      status
    }
  }
`;
export const removePolicyFromGroupMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $group_name: String = ""
    $policy_name: String = ""
  ) {
    iam_v1_RemovePolicyFromGroup(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        group_name: $group_name
      }
    ) {
      status
    }
  }
`;

export const iamQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $group_names: [String] = ""
    $policy_names: [String] = ""
    $usernames: [String] = ""
  ) {
    v1_iam_IAM_Query(
      input: {
        account_id: $account_id
        group_names: $group_names
        policy_names: $policy_names
        usernames: $usernames
      }
    ) {
      account_id
      groups {
        account_id
        created_at
        description
        group_id
        group_name
        permissions
        updated_at
      }
      policies {
        account_id
        created_at
        description
        permissions
        policy_id
        policy_name
        updated_at
      }
      users {
        account_id
        created_at
        email
        first_name
        last_name
        permissions
        type
        updated_at
        username
      }
    }
  }
`;

export const getGroupQuery = gql`
  query MyQuery($group_name: String = "", $account_id: String = "") {
    iam_v1_GetGroup(
      input: { account_id: $account_id, group_name: $group_name }
    ) {
      group {
        account_id
        created_at
        description
        group_type
        group_name
        permissions
        policy_name
        updated_at
      }
    }
  }
`;

export const updateGroupMut = gql`
  mutation MyMutation(
    $group_name: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    iam_v1_UpdateGroup(
      input: {
        account_id: $account_id
        description: $description
        group_name: $group_name
      }
    )
  }
`;

export const readUserQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $with_attached_groups: Boolean = false
  ) {
    iam_v1_GetUser(
      input: {
        account_id: $account_id
        username: $username
        with_attached_groups: $with_attached_groups
      }
    ) {
      user {
        account_id
        account_type
        antiphish_token
        billing_status
        created_at
        email
        first_name
        last_name
        permissions
        updated_at
        user_type
        username
      }
    }
  }
`;

export const updateUserMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $antiphish_token: String = ""
    $email: String = ""
    $first_name: String = ""
    $last_name: String = ""
    $username: String = ""
  ) {
    iam_v1_UpdateUser(
      input: {
        account_id: $account_id
        antiphish_token: $antiphish_token
        email: $email
        first_name: $first_name
        last_name: $last_name
        username: $username
      }
    )
  }
`;

export const readPolicyQuery = gql`
  query MyQuery($account_id: String = "", $policy_name: String = "") {
    iam_v1_GetPolicy(
      input: { policy_name: $policy_name, account_id: $account_id }
    ) {
      policy {
        account_id
        created_at
        description
        permissions
        policy_name
        policy_type
        updated_at
      }
    }
  }
`;
export const updatePolicyMut = gql`
  mutation MyMutation(
    $policy_name: String = ""
    $permissions: [String] = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    iam_v1_UpdatePolicy(
      input: {
        account_id: $account_id
        description: $description
        permissions: $permissions
        policy_name: $policy_name
      }
    )
  }
`;

export const createAccessKeyMut = gql`
  mutation MyMutation($account_id: String = "", $username: String = "") {
    iam_v1_CreateKeys(input: { account_id: $account_id, username: $username }) {
      access_key
      secret_key
    }
  }
`;

export const updateAccessKeyMut = gql`
  mutation MyMutation(
    $access_key: String = ""
    $account_id: String = ""
    $active: Boolean = false
    $username: String = ""
  ) {
    iam_v1_UpdateKeys(
      input: {
        access_key: $access_key
        account_id: $account_id
        active: $active
        username: $username
      }
    ) {
      access_key {
        access_key
        account_id
        active
        created_at
        updated_at
        username
      }
    }
  }
`;

export const deleteAccessKeyMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $access_key: String = ""
    $username: String = ""
  ) {
    iam_v1_DeleteKeys(
      input: {
        account_id: $account_id
        access_key: $access_key
        username: $username
      }
    ) {
      status
    }
  }
`;
export const setupSSOMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $type: String = ""
    $tenant_id: String = ""
    $client_secret: String = ""
    $client_id: String = ""
  ) {
    iam_v1_SetupSSO(
      input: {
        account_id: $account_id
        config: {
          client_id: $client_id
          client_secret: $client_secret
          tenant_id: $tenant_id
          type: $type
        }
      }
    ) {
      status
    }
  }
`;

export const updateSSOMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $client_id: String = ""
    $client_secret: String = ""
    $type: String = ""
  ) {
    iam_v1_UpdateSSO(
      input: {
        account_id: $account_id
        client_id: $client_id
        client_secret: $client_secret
        type: $type
      }
    ) {
      status
    }
  }
`;

export const deleteSSOMut = gql`
  mutation MyMutation($account_id: String = "") {
    iam_v1_DeleteSSO(input: { account_id: $account_id }) {
      status
    }
  }
`;

export const readSSOQuery = gql`
  query MyQuery($account_alias: String = "") {
    iam_v1_GetSSO(input: { account_alias: $account_alias }) {
      state
      type
      url
    }
  }
`;

export const getSSOConfigQuery = gql`
  query MyQuery($account_id: String = "", $username: String = "") {
    iam_v1_GetSSOConfig(
      input: { account_id: $account_id, username: $username }
    ) {
      config {
        client_id
        client_secret
        tenant_id
        type
      }
    }
  }
`;

export const readAllPermissionsQuery = gql`
  query MyQuery($account_id: String = "") {
    iam_v1_ListAllowedPermissions(input: { account_id: $account_id }) {
      allowed_permissions
    }
  }
`;

export const listUserGroupsQuery = gql`
  query MyQuery($account_id: String = "", $username: String = "") {
    iam_v1_ListUserGroups(
      input: { account_id: $account_id, username: $username }
    ) {
      groups
    }
  }
`;

export const listUserKeysQuery = gql`
  query MyQuery($account_id: String = "", $username: String = "") {
    iam_v1_ListKeys(input: { account_id: $account_id, username: $username }) {
      access_keys {
        access_key
        account_id
        active
        created_at
        updated_at
        username
      }
      last_evaluated_key {
        gsi1_pk
        gsi1_sk
        pk
        sk
      }
    }
  }
`;

export const listUserKeysByLastEvaluatedKeyQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $last_evaluated_key: api_iam_v1_LastEvaluated_Input = {}
    $username: String = ""
  ) {
    iam_v1_ListKeys(
      input: {
        account_id: $account_id
        last_evaluated_key: $last_evaluated_key
        username: $username
      }
    ) {
      access_keys {
        access_key
        account_id
        active
        created_at
        updated_at
        username
      }
      last_evaluated_key {
        gsi1_pk
        gsi1_sk
        pk
        sk
      }
    }
  }
`;

export const listGroupUsersQuery = gql`
  query MyQuery($account_id: String = "", $group_name: String = "") {
    iam_v1_ListGroupUsers(
      input: { account_id: $account_id, group_name: $group_name }
    ) {
      users
    }
  }
`;

export const listGroupPoliciesQuery = gql`
  query MyQuery($account_id: String = "", $group_name: String = "") {
    iam_v1_ListGroupPolicies(
      input: { account_id: $account_id, group_name: $group_name }
    ) {
      policies
    }
  }
`;

export const acceptInviteMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $token: String = ""
    $username: String = ""
  ) {
    iam_v1_AcceptInvite(
      input: { account_id: $account_id, token: $token, username: $username }
    )
  }
`;

export const getCompanyStationQuery = gql`
  query MyQuery($recaptcha_code: String = "", $alias: String = "") {
    iam_v1_GetCompanyStation(
      input: { alias: $alias, recaptcha_code: $recaptcha_code }
    ) {
      account_id
    }
  }
`;

export const requestAuthTransferMut = gql`
  mutation MyMutation($username: String = "", $account_id: String = "") {
    iam_v1_RequestAuthTransfer(
      input: { account_id: $account_id, username: $username }
    ) {
      token
    }
  }
`;

export const getTranserAuthQuery = gql`
  query MyQuery($token: String = "", $recaptcha_code: String = "") {
    iam_v1_GetTransferAuth(
      input: { recaptcha_code: $recaptcha_code, token: $token }
    ) {
      auth_token
    }
  }
`;

export const getAliasQuery = gql`
  query MyQuery($account_id: String = "", $username: String = "") {
    iam_v1_GetAlias(input: { username: $username, account_id: $account_id }) {
      alias
      created_at
    }
  }
`;

export const createAliasMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $alias: String = ""
    $username: String = ""
  ) {
    iam_v1_CreateAlias(
      input: { account_id: $account_id, alias: $alias, username: $username }
    ) {
      alias
    }
  }
`;

export const updateAliasMut = gql`
  mutation MyMutation(
    $username: String = ""
    $new_alias: String = ""
    $alias: String = ""
    $account_id: String = ""
  ) {
    iam_v1_UpdateAlias(
      input: {
        account_id: $account_id
        alias: $alias
        new_alias: $new_alias
        username: $username
      }
    )
  }
`;

export const deleteAliasMut = gql`
  mutation MyMutation(
    $username: String = ""
    $alias: String = ""
    $account_id: String = ""
  ) {
    iam_v1_DeleteAlias(
      input: { account_id: $account_id, alias: $alias, username: $username }
    )
  }
`;
