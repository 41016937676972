import { sendMutationRequest, sendQueryRequest, client } from "./apollo-client";
import {
  checkConfirmCodeMut,
  sendLoginCodeMut,
  iamLoginRootMutation,
  loginRootMutation,
  logoutMutation,
  sendConfirmCodeMut,
  signUpMut,
  userProfileMutation,
  loginSSOMut,
  sendLoginCodeIAMMut,
  getStripeSessionQuery,
} from "../graphql/auth";

export const loginRequest = (email, verify_token, captcha_code) => {
  const promise = sendMutationRequest({
    mutation: loginRootMutation,
    variables: {
      email: email,
      verify_token: verify_token,
      recaptcha_code: captcha_code,
    },
  });
  promise.then(() => client.resetStore());
  return promise;
};

export const loginSSORequest = ({ code, state, type }) => {
  return sendMutationRequest({
    mutation: loginSSOMut,
    variables: {
      code: code,
      state: state,
      type: type,
    },
    context: {
      headers: {
        state: state
      }
    }
  });
};

export const sendLoginCodeRequest = (email, recaptcha_code) => {
  return sendMutationRequest({
    mutation: sendLoginCodeMut,
    variables: { email: email, recaptcha_code: recaptcha_code },
  });
};

export const sendLoginCodeIAMRequest = (
  account_id,
  username,
  recaptcha_code
) => {
  return sendMutationRequest({
    mutation: sendLoginCodeIAMMut,
    variables: {
      account_id: account_id,
      username: username,
      recaptcha_code: recaptcha_code,
    },
  });
};

export const iamLoginRequest = (
  account_id,
  username,
  verify_token,
  captcha_code
) => {
  const promise = sendMutationRequest({
    mutation: iamLoginRootMutation,
    variables: {
      account_id: account_id,
      username: username,
      verify_token: verify_token,
      recaptcha_code: captcha_code,
    },
  });
  promise.then(() => client.resetStore());
  return promise;
};

export const logoutRequest = () => {
  return sendMutationRequest({ mutation: logoutMutation });
};

export const getUserProfile = () => {
  return sendMutationRequest({ mutation: userProfileMutation });
};

export const getIpAddress = async () => {
  const { default: axios } = await import("axios");
  // const ipAddress = localStorage.getItem("ip-address");
  // if (ipAddress) {
  //   return ipAddress;
  // }
  const res = await axios.get(`https://ip.${process.env.DOMAIN}/`);
  const resIP = res.data;
  let ipAddress = resIP;
  if (resIP.includes(",")) {
    const ipArray = resIP.split(",");
    ipAddress = ipArray[ipArray.length - 1].trim();
  }
  localStorage.setItem("ip-address", ipAddress);
  return ipAddress;
};

export const sendConfirmCodeRequest = (email, type, captcha_code) => {
  return sendMutationRequest({
    mutation: sendConfirmCodeMut,
    variables: {
      email: email,
      type: type,
      recaptcha_code: captcha_code,
    },
  });
};

export const checkConfirmCode = (email, token, captcha_code) => {
  return sendMutationRequest({
    mutation: checkConfirmCodeMut,
    variables: {
      email: email,
      verify_token: token,
      recaptcha_code: captcha_code,
    },
  });
};

export const signUpRequest = (input) => {
  return sendMutationRequest({ mutation: signUpMut, variables: input });
};

export const getStripeSession = () => {
  return sendQueryRequest({
    query: getStripeSessionQuery,
  });
};
