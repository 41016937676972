import { gql } from "@apollo/client";

export const createShareMut = gql`
mutation MyMutation($access_limit: Int = 10, $access_device: api_sypher_v1_SendDeviceType = SEND_DEVICE_TYPE_ALL_UNSPECIFIED, $account_id: String = "", $download_allowed: Boolean = false, $enable_notifications: Boolean = false, $encrypted_data: String = "", $end_date: String = "", $files: [api_sypher_v1_File_Input] = {}, $hide_identity: Boolean = false, $hide_stats: Boolean = false, $label: String = "", $login_required: Boolean = false, $password_set: Boolean = false, $start_date: String = "", $username: String = "") {
  sypher_v1_CreateSend(
    input: {access_device: $access_device, access_limit: $access_limit, account_id: $account_id, download_allowed: $download_allowed, enable_notifications: $enable_notifications, encrypted_data: $encrypted_data, end_date: $end_date, files: $files, hide_identity: $hide_identity, hide_stats: $hide_stats, label: $label, login_required: $login_required, password_set: $password_set, start_date: $start_date, username: $username}
  ) {
    send_id
    signed_urls
  }
}
`;

export const viewSecuredDataQuery = gql`
mutation MyMutation($send_id: String = "") {
  sypher_v1_AccessSend(
    input: {send_id: $send_id}
  ) {
    send {
      access_device
      access_limit
      account_id
      created_at
      download_allowed
      enable_notifications
      encrypted_data
      end_date
      files {
        id
        size
      }
      hide_identity
      hide_stats
      label
      login_required
      password_set
      send_id
      send_type
      start_date
      username
    }
    signed_urls
  }
}
`;


export const getSendQuery = gql`
query MyQuery($account_id: String = "", $source_id: String = "", $username: String = "") {
  sypher_v1_ListAccessHistory(
    input: {account_id: $account_id, source_id: $source_id, username: $username}
  ) {
    access_histories {
      access_id
      account_id
      as
      asname
      city
      client_ip
      continent
      continent_code
      country
      country_code
      created_at
      district
      hosting
      isp
      lat
      lon
      mobile
      offset
      org
      proxy
      region
      region_name
      status
      timezone
      user_agent
      username
      zip
    }
    last_evaluated_access_history {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
  }
}
`;

export const getSendByLastEvaluatedAccessHistoryQuery = gql`
query MyQuery($account_id: String = "", $source_id: String = "", $username: String = "", $last_evaluated_access_history: api_sypher_v1_LastEvaluated_Input = {}) {
  sypher_v1_ListAccessHistory(
    input: {account_id: $account_id, source_id: $source_id, username: $username, last_evaluated_access_history: $last_evaluated_access_history}
  ) {
    access_histories {
      access_id
      account_id
      as
      asname
      city
      client_ip
      continent
      continent_code
      country
      country_code
      created_at
      district
      hosting
      isp
      lat
      lon
      mobile
      offset
      org
      proxy
      region
      region_name
      status
      timezone
      user_agent
      username
      zip
    }
    last_evaluated_access_history {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
  }
}
`

export const dashboardQuery = gql`
query MyQuery($account_id: String = "", $month_year: String = "", $username: String = "") {
  sypher_v1_GetUsage(
    input: {account_id: $account_id, month_year: $month_year, username: $username}
  ) {
    file_count
    request_count
    response_count
    send_count
    total_data
  }
}
`;

export const postsByUsernameQuery = gql`
query MyQuery($account_id: String = "", $end_date: String = "", $start_date: String = "", $username: String = "") {
  sypher_v1_ListSends(
    input: {account_id: $account_id, end_date: $end_date, start_date: $start_date, username: $username}
  ) {
    last_evaluated_send {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
    sends {
      access_device
      access_limit
      account_id
      created_at
      download_allowed
      enable_notifications
      end_date
      files {
        id
        size
      }
      hide_identity
      hide_stats
      label
      login_required
      password_set
      send_id
      send_type
      start_date
      username
    }
  }
}
`;

export const postsByUsernameWithLastRecordQuery = gql`
query MyQuery($account_id: String = "", $end_date: String = "", $start_date: String = "", $username: String = "", $last_evaluated_send: api_sypher_v1_LastEvaluated_Input = {}) {
  sypher_v1_ListSends(
    input: {account_id: $account_id, end_date: $end_date, start_date: $start_date, username: $username, last_evaluated_send: $last_evaluated_send}
  ) {
    last_evaluated_send {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
    sends {
      access_device
      access_limit
      account_id
      created_at
      download_allowed
      enable_notifications
      end_date
      files {
        id
        size
      }
      hide_identity
      hide_stats
      label
      login_required
      password_set
      send_id
      send_type
      start_date
      username
    }
  }
}
`;

export const postByStatsQuery = gql`
  mutation MyMutation(
    $account_id: String = ""
    $end_date: String = ""
    $start_date: String = ""
    $username: String = ""
    $last_evaluated_post: api_sypher_v1_LastEvaluated_Input = {}
    $uuid: String = ""
    $username1: String = ""
    $start_date1: String = ""
    $end_date1: String = ""
    $account_id1: String = ""
  ) {
    sypher_v1_Post_ListAccessHistory(
      input: {
        account_id: $account_id1
        end_date: $end_date1
        start_date: $start_date1
        username: $username1
        uuid: $uuid
      }
    ) {
      access_histories {
        zip
        uuid
        username
        user_agent
        timezone
        status
        region_name
        region
        proxy
        org
        offset
        mobile
        lon
        lat
        isp
        hosting
        district
        created_at
        country_code
        country
        continent_code
        continent
        city
        client_ip
        asname
        as
        account_id
      }
    }
  }
`;

export const requestDataMut = gql`
mutation MyMutation($username: String = "", $signer_files: JSON = "", $data: Byte = "", $start_date: String = "", $responder_controls: Boolean = false, $respondent_ids: [String] = "", $message: String = "", $login_required: Boolean = false, $label: String = "", $hide_stats: Boolean = false, $hide_identity: Boolean = false, $end_date: String = "", $enc_key: String = "", $enable_notifications: Boolean = false, $enc_dec_key: String = "", $account_id: String = "", $access_limit: Int = 10) {
  sypher_v1_CreateRequest(
    input: {end_date: $end_date, enable_notifications: $enable_notifications, access_limit: $access_limit, enc_key: $enc_key, account_id: $account_id, enc_dec_key: $enc_dec_key, hide_identity: $hide_identity, hide_stats: $hide_stats, label: $label, login_required: $login_required, message: $message, respondent_ids: $respondent_ids, responder_controls: $responder_controls, start_date: $start_date, username: $username, template: {data: $data, signer_files: $signer_files}}
  ) {
    request_ids {
      request_id
      respondent_id
    }
  }
}
`;

export const readRequestQuery = gql`
mutation MyMutation($request_id: String = "") {
  sypher_v1_AccessRequest(
    input: {request_id: $request_id}
  ) {
    request {
      access_limit
      account_id
      enable_notifications
      enc_key
      enc_dec_key
      end_date
      hide_identity
      login_required
      hide_stats
      message
      request_id
      respondent_id
      responder_controls
      start_date
      template {
        signer_files
        data
      }
      username
    }
    session
  }
}
`;

export const readRootKeyQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $backup: Boolean = false
    $username: String = ""
  ) {
    sypher_v1_GetRootKey(
      input: { account_id: $account_id, backup: $backup, username: $username }
    ) {
      root_key
    }
  }
`;

export const createRootKeyMut = gql`
  mutation MyMutation(
    $username: String = ""
    $root_key: String = ""
    $back_root_key: String = ""
    $account_id: String = ""
  ) {
    sypher_v1_CreateRootKey(
      input: {
        account_id: $account_id
        back_root_key: $back_root_key
        root_key: $root_key
        username: $username
      }
    ) {
      root_key
    }
  }
`;

export const updateRootKeyMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $back_root_key: String = ""
    $root_key: String = ""
    $username: String = ""
  ) {
    sypher_v1_UpdateRootKey(
      input: {
        account_id: $account_id
        back_root_key: $back_root_key
        root_key: $root_key
        username: $username
      }
    ) {
      root_key
    }
  }
`;

export const submitRequestDataMut = gql`
  mutation MyMutation(
    $session_key: String = ""
    $uuid: String = ""
    $requested_files: [v1_secureshare_RequestFiles_Input] = {
      file_name: ""
      file_required: false
      submited_file_name: ""
      url: ""
    }
    $requested_fields: [v1_secureshare_RequestFields_Input] = {
      field_name: ""
      value: ""
      field_required: false
    }
    $note: String = ""
    $req_email: String = ""
  ) {
    v1_secureshare_SecureShare_SubmitRequestedData(
      input: {
        session_key: $session_key
        uuid: $uuid
        requested_files: $requested_files
        requested_fields: $requested_fields
        note: $note
        req_email: $req_email
      }
    ) {
      status
    }
  }
`;

export const readRequestedDataQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $request_id: String = ""
    $username: String = ""
  ) {
    sypher_v1_GetRequest(
      input: {
        account_id: $account_id
        request_id: $request_id
        username: $username
      }
    ) {
      request {
        access_limit
        account_id
        email
        enable_notifications
        end_date
        hide_identity
        hide_stats
        login_required
        message
        request_id
        start_date
        template {
          fields
        }
        username
      }
    }
  }
`;

export const requestHistoryQuery = gql`
query MyQuery($username: String = "", $start_date: String = "", $end_date: String = "", $account_id: String = "") {
  sypher_v1_ListRequests(
    input: {account_id: $account_id, end_date: $end_date, start_date: $start_date, username: $username}
  ) {
    requests {
      username
      template {
        data
        signer_files
      }
      start_date
      responder_controls
      respondent_id
      request_id
      message
      label
      login_required
      hide_stats
      hide_identity
      end_date
      enc_dec_key
      enc_key
      enable_notifications
      account_id
      access_limit
      created_at
    }
    last_evaluated_request {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
  }
}
`;
export const requestHistoryByLastEvalutedPostQuery = gql`
query MyQuery($username: String = "", $start_date: String = "", $end_date: String = "", $account_id: String = "", $last_evaluated_request: api_sypher_v1_LastEvaluated_Input = {}) {
  sypher_v1_ListRequests(
    input: {account_id: $account_id, end_date: $end_date, start_date: $start_date, username: $username, last_evaluated_request: $last_evaluated_request}
  ) {
    requests {
      username
      template {
        data
        signer_files
      }
      start_date
      responder_controls
      respondent_id
      request_id
      message
      label
      login_required
      hide_stats
      hide_identity
      end_date
      enc_dec_key
      enc_key
      enable_notifications
      account_id
      access_limit
      created_at
    }
    last_evaluated_request {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
  }
}
`;

export const notificationsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $service_name: String = ""
    $username: String = ""
  ) {
    v1_notification_center_NotificationCenter_ReadNotifications(
      input: {
        account_id: $account_id
        service_name: $service_name
        username: $username
      }
    ) {
      last_evaluated_notification {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      notifications {
        account_id
        notification
        service_name
        username
      }
    }
  }
`;

export const notificationsQueryByLastNotificationQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $service_name: String = ""
    $username: String = ""
    $last_evaluated_notification: v1_notification_center_LastEvaluated_Input = {
      Gsi1Pk: ""
      Gsi1Sk: ""
      Pk: ""
      Sk: ""
    }
  ) {
    v1_notification_center_NotificationCenter_ReadNotifications(
      input: {
        account_id: $account_id
        service_name: $service_name
        username: $username
        last_evaluated_notification: $last_evaluated_notification
      }
    ) {
      last_evaluated_notification {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      notifications {
        account_id
        notification
        service_name
        username
      }
    }
  }
`;


export const createTemplateMut = gql`
mutation MyMutation($account_id: String = "", $description: String = "", $name: String = "", $username: String = "", $template: api_sypher_v1_Template_Input = {data: "", signer_files: ""}) {
  sypher_v1_CreateTemplate(
    input: {account_id: $account_id, description: $description, name: $name, username: $username, template: $template}
  ) {
    template {
      account_id
      created_at
      description
      name
      updated_at
      username
    }
  }
}
`

export const updateTemplateMut = gql`
mutation MyMutation($template: api_sypher_v1_Template_Input = {}, $username: String = "", $name: String = "", $description: String = "", $account_id: String = "") {
  sypher_v1_UpdateTemplate(
    input: {account_id: $account_id, description: $description, name: $name, template: $template, username: $username}
  )
}
`

export const deleteTemplateMut = gql`
mutation MyMutation($account_id: String = "", $name: String = "", $username: String = "") {
  sypher_v1_DeleteTemplate(
    input: {account_id: $account_id, name: $name, username: $username}
  )
}
`

export const getTemplatesQuery = gql`
query MyQuery($account_id: String = "", $username: String = "", $name: String = "") {
  sypher_v1_ListTemplates(input: {account_id: $account_id, username: $username, name: $name}) {
    last_evaluated_template {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
    templates {
      account_id
      created_at
      description
      name
      template {
        data
        signer_files
      }
      updated_at
      username
    }
  }
}
`

export const getTemplatesByLastEvaluatedTemplateQuery = gql`
query MyQuery($account_id: String = "", $username: String = "", $last_evaluated_template: api_sypher_v1_LastEvaluated_Input = {}, $name: String = "") {
  sypher_v1_ListTemplates(
    input: {account_id: $account_id, username: $username, last_evaluated_template: $last_evaluated_template, name: $name}
  ) {
    last_evaluated_template {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
    templates {
      account_id
      created_at
      description
      name
      template {
        data
        signer_files
      }
      updated_at
      username
    }
  }
}
`

export const getTemplateQuery = gql`
query MyQuery($account_id: String = "", $name: String = "", $username: String = "") {
  sypher_v1_GetTemplate(
    input: {account_id: $account_id, name: $name, username: $username}
  ) {
    template {
      account_id
      created_at
      description
      name
      template {
        data
        signer_files
      }
      updated_at
      username
    }
  }
}
`

export const createResponseMut = gql`
mutation MyMutation($files: [api_sypher_v1_File_Input] = {}, $session: String = "") {
  sypher_v1_CreateResponse(input: {files: $files, session: $session}) {
    signed_urls
  }
}
`

export const createResponseWithLimitMut = gql`
mutation MyMutation($files: [api_sypher_v1_File_Input] = {},$start_date: String = "", $end_date: String = "", $access_limit: Int = 10, $session: String = "") {
  sypher_v1_CreateResponse(
    input: {access_limit: $access_limit, start_date: $start_date, end_date: $end_date, files: $files, session: $session}
  ) {
    signed_urls
  }
}
`

export const listResponsesQuery = gql`
query MyQuery($account_id: String = "", $end_date: String = "", $start_date: String = "", $username: String = "") {
  sypher_v1_ListResponses(
    input: {account_id: $account_id, end_date: $end_date, start_date: $start_date, username: $username}
  ) {
    responses {
      responder_controls
      access_limit
      account_id
      created_at
      enc_dec_key
      end_date
      requester_id
      requester_type
      respondent_id
      response_id
      start_date
      template {
        data
        signer_files
      }
      username
    }
    last_evaluated_response {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
  }
}
`

export const listResponsesWithLastEvaluatedResponseQuery = gql`
query MyQuery($account_id: String = "", $end_date: String = "", $start_date: String = "", $username: String = "", $last_evaluated_response: api_sypher_v1_LastEvaluated_Input = {}) {
  sypher_v1_ListResponses(
    input: {account_id: $account_id, end_date: $end_date, start_date: $start_date, username: $username, last_evaluated_response: $last_evaluated_response}
  ) {
    responses {
      responder_controls
      access_limit
      account_id
      created_at
      enc_dec_key
      end_date
      requester_id
      requester_type
      respondent_id
      response_id
      start_date
      template {
        data
        signer_files
      }
      username
    }
    last_evaluated_response {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
  }
}
`

export const getResponseQuery = gql`
query MyQuery($account_id: String = "", $requester_id: String = "", $respondent_id: String = "", $username: String = "") {
  sypher_v1_GetResponse(
    input: {account_id: $account_id, requester_id: $requester_id, respondent_id: $respondent_id, username: $username}
  ) {
    response {
      access_limit
      account_id
      created_at
      enc_dec_key
      end_date
      requester_id
      respondent_id
      requester_type
      responder_controls
      response_id
      start_date
      template {
        data
        signer_files
      }
      username
    }
    signed_urls
  }
}
`

export const getSendAccessHistoryByUsernameQuery = gql`
query MyQuery($account_id: String = "", $start_date: String = "", $end_date: String = "", $username: String = "") {
  sypher_v1_ListAccessHistoryByUsername(
    input: {account_id: $account_id, username: $username, start_date: $start_date, end_date: $end_date}
  ) {
    last_evaluated_access_history {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
    access_histories {
      access_id
      account_id
      as
      asname
      city
      client_ip
      continent
      continent_code
      country
      country_code
      created_at
      district
      hosting
      isp
      lat
      lon
      mobile
      offset
      org
      proxy
      region
      region_name
      status
      timezone
      user_agent
      username
      zip
    }
  }
}
`

export const getSendAccessHistoryByUsernameByLastEvaluatedAccessHistoryQuery = gql`
query MyQuery($account_id: String = "", $start_date: String = "", $end_date: String = "", $username: String = "", $last_evaluated_access_history: api_sypher_v1_LastEvaluated_Input = {}) {
  sypher_v1_ListAccessHistoryByUsername(
    input: {account_id: $account_id, username: $username, start_date: $start_date, end_date: $end_date, last_evaluated_access_history: $last_evaluated_access_history}
  ) {
    last_evaluated_access_history {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
    access_histories {
      access_id
      account_id
      as
      asname
      city
      client_ip
      continent
      continent_code
      country
      country_code
      created_at
      district
      hosting
      isp
      lat
      lon
      mobile
      offset
      org
      proxy
      region
      region_name
      status
      timezone
      user_agent
      username
      zip
    }
  }
}
`


export const getRequestQuery = gql`
query MyQuery($account_id: String = "", $request_id: String = "", $respondent_id: String = "", $username: String = "") {
  sypher_v1_GetRequest(
    input: {account_id: $account_id, request_id: $request_id, respondent_id: $respondent_id, username: $username}
  ) {
    request {
      access_limit
      account_id
      created_at
      enable_notifications
      enc_dec_key
      enc_key
      end_date
      hide_identity
      hide_stats
      label
      login_required
      message
      request_id
      respondent_id
      responder_controls
      start_date
      template {
        data
        signer_files
      }
      username
    }
  }
}
`

export const updateRequestMut = gql`
mutation MyMutation($access_limit: Int = 10, $account_id: String = "", $enable_notifications: Boolean = false, $hide_identity: Boolean = false, $hide_stats: Boolean = false, $login_required: Boolean = false, $request_id: String = "", $username: String = "", $responder_controls: Boolean = false) {
  sypher_v1_UpdateRequest(
    input: {access_limit: $access_limit, account_id: $account_id, enable_notifications: $enable_notifications, hide_identity: $hide_identity, hide_stats: $hide_stats, login_required: $login_required, request_id: $request_id, username: $username, responder_controls: $responder_controls}
  )
}
`

export const createDropzoneMut = gql`
mutation MyMutation($data: Byte = "", $signer_files: JSON = "", $username: String = "", $start_date: String = "", $responder_controls: Boolean = false, $password: String = "", $login_required: Boolean = false, $end_date: String = "", $enc_key: String = "", $enc_dec_key: String = "", $description: String = "", $allowed_origins: [String] = "", $account_id: String = "", $dropzone_name: String = "") {
  sypher_v1_CreateDropzone(
    input: {account_id: $account_id, allowed_origins: $allowed_origins, enc_key: $enc_key, enc_dec_key: $enc_dec_key, end_date: $end_date, login_required: $login_required, password: $password, responder_controls: $responder_controls, start_date: $start_date, username: $username, template: {data: $data, signer_files: $signer_files}, description: $description, dropzone_name: $dropzone_name}
  ) {
    dropzone {
      account_id
      allowed_origins
      created_at
      description
      dropzone_id
      dropzone_key
      dropzone_name
      enc_dec_key
      enc_key
      end_date
      login_required
      password
      responder_controls
      start_date
      template {
        data
        signer_files
      }
      username
    }
  }
}
`

export const accessDropzoneMut = gql`
mutation MyMutation($dropzone_key: String = "", $dropzone_id: String = "", $password: String = "", $respondent_id: String = "") {
  sypher_v1_AccessDropzone(
    input: {dropzone_key: $dropzone_key, dropzone_id: $dropzone_id, password: $password, respondent_id: $respondent_id}
  ) {
    session
    dropzone {
      account_id
      allowed_origins
      created_at
      description
      dropzone_id
      dropzone_key
      dropzone_name
      enc_dec_key
      enc_key
      end_date
      login_required
      password
      responder_controls
      start_date
      username
      template {
        data
        signer_files
      }
    }
  }
}
`

export const accessDropzoneWithoutPasswordMut = gql`
mutation MyMutation($respondent_id: String = "", $dropzone_id: String = "", $dropzone_key: String = "") {
  sypher_v1_AccessDropzone(
    input: {dropzone_key: $dropzone_key, dropzone_id: $dropzone_id, respondent_id: $respondent_id}
  ) {
    dropzone {
      account_id
      allowed_origins
      created_at
      description
      dropzone_id
      dropzone_key
      dropzone_name
      enc_dec_key
      enc_key
      end_date
      login_required
      password
      responder_controls
      start_date
      template {
        data
        signer_files
      }
      username
    }
    session
  }
}
`

export const updateDropzoneMut = gql`
mutation MyMutation($account_id: String = "", $allowed_origins: [String] = "", $dropzone_id: String = "", $password: String = "", $login_required: Boolean = false, $reset_dropzone_key: Boolean = false, $responder_controls: Boolean = false, $username: String = "", $dropzone_name: String = "") {
  sypher_v1_UpdateDropzone(
    input: {account_id: $account_id, allowed_origins: $allowed_origins, dropzone_id: $dropzone_id, password: $password, login_required: $login_required, reset_dropzone_key: $reset_dropzone_key, responder_controls: $responder_controls, username: $username, dropzone_name: $dropzone_name}
  ) {
    dropzone_key
  }
}
`

export const listDropzonesQuery = gql`
query MyQuery($account_id: String = "", $end_date: String = "", $start_date: String = "", $username: String = "") {
  sypher_v1_ListDropzones(
    input: {account_id: $account_id, end_date: $end_date, start_date: $start_date, username: $username}
  ) {
    dropzones {
      account_id
      allowed_origins
      created_at
      description
      dropzone_id
      dropzone_key
      dropzone_name
      enc_dec_key
      enc_key
      end_date
      login_required
      password
      responder_controls
      start_date
      username
      template {
        data
        signer_files
      }
    }
    last_evaluated_dropzone {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
  }
}
`

export const listDropzonesByLastEvaluatedDropzoneQuery = gql`
query MyQuery($account_id: String = "", $end_date: String = "", $start_date: String = "", $username: String = "", $last_evaluated_dropzone: api_sypher_v1_LastEvaluated_Input = {}) {
  sypher_v1_ListDropzones(
    input: {account_id: $account_id, end_date: $end_date, start_date: $start_date, username: $username, last_evaluated_dropzone: $last_evaluated_dropzone}
  ) {
    dropzones {
      account_id
      allowed_origins
      created_at
      description
      dropzone_key
      dropzone_id
      dropzone_name
      enc_dec_key
      enc_key
      end_date
      login_required
      password
      responder_controls
      start_date
      username
      template {
        data
        signer_files
      }
    }
    last_evaluated_dropzone {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
  }
}
`

export const getDropzoneQuery = gql`
query MyQuery($account_id: String = "", $dropzone_id: String = "", $username: String = "") {
  sypher_v1_GetDropzone(
    input: {account_id: $account_id, dropzone_id: $dropzone_id, username: $username}
  ) {
    dropzone {
      account_id
      allowed_origins
      created_at
      dropzone_key
      description
      dropzone_id
      dropzone_name
      enc_dec_key
      enc_key
      end_date
      login_required
      password
      responder_controls
      start_date
      username
      template {
        data
        signer_files
      }
    }
  }
}
`

export const getDashboardDataQuery = gql`
query MyQuery($account_id: String = "", $month_year: String = "" $username: String = "", $start_date: String = "", $end_date: String = "") {
  sypher_v1_GetUsage(
    input: {account_id: $account_id, month_year: $month_year, username: $username}
  ) {
    dropzone_count
    file_count
    request_count
    response_count
    send_count
    total_data
  }
  sypher_v1_ListResponses(
    input: {end_date: $end_date, start_date: $start_date, username: $username, account_id: $account_id}
  ) {
    responses {
      username
      start_date
      response_id
      respondent_id
      responder_controls
      requester_type
      requester_id
      end_date
      created_at
      account_id
      access_limit
    }
  }
  sypher_v1_ListAccessHistoryByUsername(
    input: {account_id: $account_id, start_date: $start_date, end_date: $end_date, username: $username}
  ) {
    access_histories {
      account_id
      lat
      lon
    }
  }
  sypher_v1_ListDropZoneResponses(
    input: {username: $username, start_date: $start_date, end_date: $end_date, account_id: $account_id}
  ) {
    responses {
      respondent_id
      requester_id
      start_date
      end_date
      access_limit
      requester_type
      requester_id
      created_at
    }
  }
}
`

export const dropzoneResponsesWithDropzoneKeyQuery = gql`
query MyQuery($username: String = "", $start_date: String = "", $end_date: String = "", $account_id: String = "", $dropzone_name: String = "") {
  sypher_v1_ListDropZoneResponses(
    input: {account_id: $account_id, end_date: $end_date, start_date: $start_date, username: $username, dropzone_name: $dropzone_name}
  ) {
    last_evaluated_response {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
    responses {
      access_limit
      account_id
      created_at
      enc_dec_key
      end_date
      files {
        id
        size
      }
      requester_id
      respondent_id
      requester_type
      responder_controls
      response_id
      start_date
      username
      template {
        data
        signer_files
      }
    }
  }
}
`

export const dropzoneResponsesWithDropzoneKeyByLastEvaluatedRepsonseQuery = gql`
query MyQuery($username: String = "", $start_date: String = "", $end_date: String = "", $account_id: String = "", $dropzone_name: String = "", $last_evaluated_response: api_sypher_v1_LastEvaluated_Input = {}) {
  sypher_v1_ListDropZoneResponses(
    input: {account_id: $account_id, end_date: $end_date, start_date: $start_date, username: $username, dropzone_name: $dropzone_name, last_evaluated_response: $last_evaluated_response}
  ) {
    last_evaluated_response {
      gsi1Pk
      gsi1Sk
      sk
      pk
    }
    responses {
      access_limit
      account_id
      created_at
      enc_dec_key
      end_date
      files {
        id
        size
      }
      requester_id
      respondent_id
      requester_type
      responder_controls
      response_id
      start_date
      username
      template {
        data
        signer_files
      }
    }
  }
}
`

export const dropzoneResponsesQuery = gql`
query MyQuery($account_id: String = "", $end_date: String = "", $start_date: String = "", $username: String = "") {
  sypher_v1_ListDropZoneResponses(
    input: {username: $username, start_date: $start_date, end_date: $end_date, account_id: $account_id}
  ) {
    responses {
      respondent_id
      start_date
      end_date
      access_limit
      requester_type
      requester_id
      enc_dec_key
      created_at
      account_id
      files {
        size
        id
      }
      responder_controls
      response_id
      template {
        data
        signer_files
      }
      username
    }
    last_evaluated_response {
      gsi1Pk
      gsi1Sk
      pk
      sk
    }
  }
}
` 

export const dropzoneResponsesByLastEvaluatedRepsonseQuery = gql`
query MyQuery($account_id: String = "", $end_date: String = "", $start_date: String = "", $username: String = "", $last_evaluated_response: api_sypher_v1_LastEvaluated_Input = {}) {
  sypher_v1_ListDropZoneResponses(
    input: {username: $username, start_date: $start_date, end_date: $end_date, account_id: $account_id, last_evaluated_response: $last_evaluated_response}
  ) {
    responses {
      respondent_id
      start_date
      end_date
      access_limit
      requester_type
      requester_id
      enc_dec_key
      created_at
      account_id
      files {
        size
        id
      }
      responder_controls
      response_id
      template {
        data
        signer_files
      }
      username
    }
    last_evaluated_response {
      gsi1Pk
      gsi1Sk
      pk
      sk
    }
  }
}
`

export const listResponsesByRespondentQuery = gql`
query MyQuery($account_id: String = "", $respondent_id: String = "", $username: String = "") {
  sypher_v1_ListResponsesByRespondent(
    input: {account_id: $account_id, respondent_id: $respondent_id, username: $username}
  ) {
    responses {
      access_limit
      account_id
      created_at
      enc_dec_key
      end_date
      files {
        id
        size
      }
      requester_id
      requester_type
      respondent_id
      responder_controls
      response_id
      start_date
      template {
        data
        signer_files
      }
      username
    }
    last_evaluated_response {
      gsi1Pk
      gsi1Sk
      pk
      sk
    }
  }
}
`

export const listResponsesByRespondentByLastEvaluatedResponseQuery = gql`
query MyQuery($account_id: String = "", $respondent_id: String = "", $username: String = "", $last_evaluated_response: api_sypher_v1_LastEvaluated_Input = {}) {
  sypher_v1_ListResponsesByRespondent(
    input: {account_id: $account_id, respondent_id: $respondent_id, username: $username, last_evaluated_response: $last_evaluated_response}
  ) {
    responses {
      access_limit
      account_id
      created_at
      enc_dec_key
      end_date
      files {
        id
        size
      }
      requester_id
      requester_type
      respondent_id
      responder_controls
      response_id
      start_date
      template {
        data
        signer_files
      }
      username
    }
    last_evaluated_response {
      gsi1Pk
      gsi1Sk
      pk
      sk
    }
  }
}
`

export const updateSendMut = gql`
mutation MyMutation($username: String = "", $send_id: String = "", $login_required: Boolean = false, $label: String = "", $hide_stats: Boolean = false, $hide_identity: Boolean = false, $enable_notifications: Boolean = false, $download_allowed: Boolean = false, $account_id: String = "", $access_limit: Int = 10, $access_device: api_sypher_v1_SendDeviceType = SEND_DEVICE_TYPE_ALL_UNSPECIFIED) {
  sypher_v1_UpdateSend(
    input: {access_device: $access_device, access_limit: $access_limit, account_id: $account_id, download_allowed: $download_allowed, enable_notifications: $enable_notifications, hide_identity: $hide_identity, hide_stats: $hide_stats, label: $label, login_required: $login_required, send_id: $send_id, username: $username}
  )
}
`


export const expireSendMut = gql`
mutation MyMutation($access_limit: Int = 10, $account_id: String = "", $send_id: String = "", $username: String = "") {
  sypher_v1_UpdateSend(
    input: {access_limit: $access_limit, account_id: $account_id, send_id: $send_id, username: $username}
  )
}
`
