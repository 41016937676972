import {
  createVulnerablityMut,
  deleteVulnerabilityMut,
  getVulnerablityQuery,
  listVulnerablitiesQuery,
  listVulnerablitiesWithLastEvaluatedVulnerabilityQuery,
  updateVulnerablityMut,
} from "../graphql/vilnerablity";
import { sendMutationRequest, sendQueryRequest } from "./apollo-client";

export const createVulnerability = (input) => {
  return sendMutationRequest({
    mutation: createVulnerablityMut,
    variables: input,
  });
};

export const updateVulnerability = (input) => {
  return sendMutationRequest({
    mutation: updateVulnerablityMut,
    variables: input,
  });
};

export const deleteVulnerability = (input) => {
  return sendMutationRequest({
    mutation: deleteVulnerabilityMut,
    variables: input,
  });
};

export const getVulnerablity = (input) => {
  return sendQueryRequest({
    query: getVulnerablityQuery,
    variables: input,
    fetchPolicy: "network-only",
  });
};

export const listVulnerablities = (input) => {
  return sendQueryRequest({
    query: input?.last_evaluated_vulnerability
      ? listVulnerablitiesWithLastEvaluatedVulnerabilityQuery
      : listVulnerablitiesQuery,
    variables: input,
    fetchPolicy: "network-only",
  });
};
