import {
  addAssetToStageMut,
  askAssetQuestionMut,
  askMut,
  createAssetMut,
  createCategoriesMut,
  createGlobalRelationMut,
  createLifecycleMut,
  createNoteMut,
  createRelationMut,
  createRelationWithTagsMut,
  createStageMut,
  createStageRelationMut,
  createStageWithNextStageMut,
  deleteAssetsMut,
  deleteCategoryMut,
  deleteLifecycleMut,
  deleteNoteMut,
  deleteRelationMut,
  deleteStageMut,
  deleteStageRelationMut,
  exploreAssetsQuery,
  getAssetCountByCategoryQuery,
  getAssetQuery,
  getCategoryQuery,
  getDashboardMetricsQuery,
  getLifecycleQuery,
  getNoteQuery,
  getRelationQuery,
  listAssetStagesQuery,
  listAssetWithSearchQuery,
  listAssetsAndRelationsQuery,
  listAssetsQuery,
  listCategoriesQuery,
  listLifecyclesQuery,
  listNoteQuery,
  listStageAssetsQuery,
  listStagesQuery,
  removeAssetFromStageMut,
  updateAssetMut,
  updateAssetPropMut,
  updateCategoryMut,
  updateLifecycleMut,
  updateNoteMut,
  updateRelationMut,
  updateStageMut,
  updateStageRelationMut,
} from "../graphql/asset-inventory";
import { sendMutationRequest, sendQueryRequest } from "./apollo-client";

export const createAsset = (variables) => {
  return sendMutationRequest({
    mutation: createAssetMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const updateAsset = (variables) => {
  return sendMutationRequest({
    mutation: updateAssetMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const listAssetsAndRelations = (variables) => {
  return variables?.search_term ? sendQueryRequest({
    query: listAssetWithSearchQuery,
    variables: variables,
    fetchPolicy: "network-only",
  }) : sendQueryRequest({
    query: listAssetsAndRelationsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const exploreAssets = (variables) => {
  return sendQueryRequest({
    query: exploreAssetsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const listAssets = (variables) => {
  return sendQueryRequest({
    query: listAssetsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const getAsset = (variables) => {
  return sendQueryRequest({
    query: getAssetQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const deleteAsset = (variables) => {
  return sendMutationRequest({
    mutation: deleteAssetsMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const createRelation = (variables) => {
  return sendMutationRequest({
    mutation: createRelationMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};


export const createRelationWithTags = (variables) => {
  return sendMutationRequest({
    mutation: createRelationWithTagsMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const updateRelation = (variables) => {
  return sendMutationRequest({
    mutation: updateRelationMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const getRelation = (variables) => {
  return sendQueryRequest({
    query: getRelationQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const deleteRelation = (variables) => {
  return sendMutationRequest({
    mutation: deleteRelationMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const ask = (variables) => {
  return sendMutationRequest({
    mutation: askMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

// export const askAssetQuestion = (variables) => {
//     return sendMutationRequest({
//         mutation: askAssetQuestionMut,
//         variables: variables,
//         fetchPolicy: "network-only",
//     })
// }

export const askStream = (variables) => {
  return fetch("https://graphql.dev.defencestation.ca/ai/v1/ask", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(variables),
  });
};

export const askAssetQuestionStream = (variables) => {
  return fetch("https://graphql.dev.defencestation.ca/ai/v1/asset", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(variables),
  });
};

export const createLifecycle = (variables) =>
  sendMutationRequest({
    mutation: createLifecycleMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateLifecycle = (variables) =>
  sendMutationRequest({
    mutation: updateLifecycleMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteLifecycle = (variables) =>
  sendMutationRequest({
    mutation: deleteLifecycleMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getLifecycle = (variables) =>
  sendQueryRequest({
    query: getLifecycleQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listLifecycles = (variables) =>
  sendQueryRequest({
    query: listLifecyclesQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createStage = (variables) =>
  sendMutationRequest({
    mutation: createStageMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createStageRelation = (variables) =>
  sendMutationRequest({
    mutation: createStageRelationMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateStageRelation = (variables) =>
  sendMutationRequest({
    mutation: updateStageRelationMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createStageWithNextStage = (variables) =>
  sendMutationRequest({
    mutation: createStageWithNextStageMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateStage = (variables) =>
  sendMutationRequest({
    mutation: updateStageMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteStage = (variables) =>
  sendMutationRequest({
    mutation: deleteStageMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listStages = (variables) =>
  sendQueryRequest({
    query: listStagesQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteStageRelation = (variables) =>
  sendMutationRequest({
    mutation: deleteStageRelationMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const addAssetToStage = (variables) =>
  sendMutationRequest({
    mutation: addAssetToStageMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const removeAssetFromStage = (variables) =>
  sendMutationRequest({
    mutation: removeAssetFromStageMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createCategory = (variables) =>
  sendMutationRequest({
    mutation: createCategoriesMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateCategory = (variables) =>
  sendMutationRequest({
    mutation: updateCategoryMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteCategory = (variables) =>
  sendMutationRequest({
    mutation: deleteCategoryMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getCategory = (variables) =>
  sendQueryRequest({
    query: getCategoryQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listCategories = (variables) =>
  sendMutationRequest({
    mutation: listCategoriesQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateAssetProps = (variables) =>
  sendMutationRequest({
    mutation: updateAssetPropMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listAssetStages = (variables) =>
  sendQueryRequest({
    query: listAssetStagesQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listStageAssets = (variables) =>
  sendQueryRequest({
    query: listStageAssetsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createNote = (variables) =>
  sendMutationRequest({
    mutation: createNoteMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteNote = (variables) =>
  sendMutationRequest({
    mutation: deleteNoteMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateNote = (variables) =>
  sendMutationRequest({
    mutation: updateNoteMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getNote = (variables) =>
  sendQueryRequest({
    query: getNoteQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const listNotes = (variables) =>
  sendQueryRequest({
    query: listNoteQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getDashboardMetrics = (variables) =>
  sendQueryRequest({
    query: getDashboardMetricsQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getAssetCountByCategory = (variables) =>
  sendQueryRequest({
    query: getAssetCountByCategoryQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createGlobalRelation = (variables) => sendMutationRequest({
  mutation: createGlobalRelationMut,
  variables,
  fetchPolicy: "network-only",
})
