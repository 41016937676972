import { gql } from "@apollo/client";

export const createVulnerablityMut = gql`
  mutation MyMutation2(
    $account_id: String = ""
    $additional_comments: String = ""
    $affected_versions: [String] = ""
    $business_impact: String = ""
    $category: api_vuln_management_v1_VulnerabilityCategory = MISCONFIGURATION
    $compliance_requirements: String = ""
    $current_status: String = ""
    $cvss2_score: String = ""
    $cvss3_score: String = ""
    $cwe_id: String = ""
    $date_discovered: String = ""
    $date_reported: String = ""
    $date_resolved: String = ""
    $description: String = ""
    $details: String = ""
    $documentation: String = ""
    $ease_of_resolution: api_vuln_management_v1_VulnerabilityEaseOfResolution = TRIVIAL
    $environments_affected: [String] = ""
    $epss_score: String = ""
    $external_refs: [String] = ""
    $fixed_versions: [String] = ""
    $impact: [api_vuln_management_v1_VulnerabilityImpact] = ACCOUNTABILITY
    $manual_verification: String = ""
    $patch_available: Boolean = false
    $priority: String = ""
    $product_id: String = ""
    $public_exploits: [String] = ""
    $recommendations: String = ""
    $related_incidents: [String] = ""
    $remediation_steps: String = ""
    $reported_by: String = ""
    $title: String = ""
    $type: api_vuln_management_v1_VulnerabilityType = MFA_MISSING
    $username: String = ""
    $vendor: String = ""
    $vulnerability_id: String = ""
    $technical_impact: String = ""
    $severity_rating: api_vuln_management_v1_VulnerabilitySeverityRating = INFO
  ) {
    vuln_management_v1_CreateVulnerability(
      input: {
        account_id: $account_id
        additional_comments: $additional_comments
        affected_versions: $affected_versions
        business_impact: $business_impact
        category: $category
        compliance_requirements: $compliance_requirements
        current_status: $current_status
        cvss2_score: $cvss2_score
        cvss3_score: $cvss3_score
        cwe_id: $cwe_id
        date_discovered: $date_discovered
        date_reported: $date_reported
        date_resolved: $date_resolved
        description: $description
        details: $details
        documentation: $documentation
        ease_of_resolution: $ease_of_resolution
        environments_affected: $environments_affected
        epss_score: $epss_score
        external_refs: $external_refs
        fixed_versions: $fixed_versions
        impact: $impact
        manual_verification: $manual_verification
        patch_available: $patch_available
        priority: $priority
        product_id: $product_id
        public_exploits: $public_exploits
        related_incidents: $related_incidents
        recommendations: $recommendations
        remediation_steps: $remediation_steps
        reported_by: $reported_by
        severity_rating: $severity_rating
        technical_impact: $technical_impact
        title: $title
        type: $type
        username: $username
        vendor: $vendor
        vulnerability_id: $vulnerability_id
      }
    ) {
      vulnerability {
        additional_comments
        affected_versions
        category
        business_impact
        compliance_requirements
        current_status
        cvss2_score
        cvss3_score
        cwe_id
        date_discovered
        date_reported
        date_resolved
        description
        details
        documentation
        ease_of_resolution
        epss_score
        environments_affected
        external_refs
        fixed_versions
        impact
        manual_verification
        priority
        patch_available
        public_exploits
        product_id
        related_incidents
        recommendations
        remediation_steps
        reported_by
        severity_rating
        technical_impact
        title
        type
        vendor
        vulnerability_id
      }
    }
  }
`;

export const updateVulnerablityMut = gql`
  mutation MyMutation(
    $vulnerability_id: String = ""
    $username: String = ""
    $title: String = ""
    $severity_rating: api_vuln_management_v1_VulnerabilitySeverityRating = INFO
    $reported_by: String = ""
    $remediation_steps: String = ""
    $priority: String = ""
    $patch_available: Boolean = false
    $documentation: String = ""
    $description: String = ""
    $cvss_score: String = ""
    $current_status: String = ""
    $compliance_requirements: String = ""
    $category: String = ""
    $business_impact: String = ""
    $additional_comments: String = ""
    $account_id: String = ""
  ) {
    vuln_management_v1_UpdateVulnerability(
      input: {
        account_id: $account_id
        additional_comments: $additional_comments
        business_impact: $business_impact
        category: $category
        compliance_requirements: $compliance_requirements
        current_status: $current_status
        cvss_score: $cvss_score
        description: $description
        documentation: $documentation
        patch_available: $patch_available
        priority: $priority
        remediation_steps: $remediation_steps
        reported_by: $reported_by
        severity_rating: $severity_rating
        title: $title
        type: MISCONFIGURATION
        username: $username
        vulnerability_id: $vulnerability_id
      }
    )
  }
`;

export const deleteVulnerabilityMut = gql`
  mutation MyMutation(
    $vulnerability_id: String = ""
    $username: String = ""
    $account_id: String = ""
  ) {
    vuln_management_v1_DeleteVulnerability(
      input: {
        account_id: $account_id
        username: $username
        vulnerability_id: $vulnerability_id
      }
    )
  }
`;

export const getVulnerablityQuery = gql`
  query MyQuery(
    $vulnerability_id: String = ""
    $username: String = ""
    $account_id: String = ""
  ) {
    vuln_management_v1_GetVulnerability(
      input: {
        account_id: $account_id
        username: $username
        vulnerability_id: $vulnerability_id
      }
    ) {
      vulnerability {
        additional_comments
        category
        business_impact
        compliance_requirements
        current_status
        cvss_score
        description
        documentation
        patch_available
        priority
        remediation_steps
        reported_by
        severity_rating
        title
        type
        vulnerability_id
      }
    }
  }
`;

export const listVulnerablitiesQuery = gql`
  query MyQuery(
    $username: String = ""
    $type: api_vuln_management_v1_VulnerabilityType = MISCONFIGURATION
    $severity_rating: api_vuln_management_v1_VulnerabilitySeverityRating = INFO
    $account_id: String = ""
  ) {
    vuln_management_v1_ListVulnerabilities(
      input: {
        account_id: $account_id
        severity_rating: $severity_rating
        type: $type
        username: $username
      }
    ) {
      last_evaluated_vulnerability {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      vulnerability {
        vulnerability_id
        type
        title
        severity_rating
        reported_by
        remediation_steps
        priority
        patch_available
        documentation
        description
        current_status
        cvss_score
        compliance_requirements
        category
        business_impact
        additional_comments
      }
    }
  }
`;

export const listVulnerablitiesWithLastEvaluatedVulnerabilityQuery = gql`
  query MyQuery(
    $username: String = ""
    $type: api_vuln_management_v1_VulnerabilityType = MISCONFIGURATION
    $severity_rating: api_vuln_management_v1_VulnerabilitySeverityRating = INFO
    $last_evaluated_vulnerability: api_vuln_management_v1_LastEvaluated_Input = {

    }
    $account_id: String = ""
  ) {
    vuln_management_v1_ListVulnerabilities(
      input: {
        account_id: $account_id
        severity_rating: $severity_rating
        type: $type
        username: $username
        last_evaluated_vulnerability: $last_evaluated_vulnerability
      }
    ) {
      last_evaluated_vulnerability {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      vulnerability {
        vulnerability_id
        type
        title
        severity_rating
        reported_by
        remediation_steps
        priority
        patch_available
        documentation
        description
        current_status
        cvss_score
        compliance_requirements
        category
        business_impact
        additional_comments
      }
    }
  }
`;
