import { gql } from "@apollo/client";

export const listPoliciesQuery = gql`
  query MyQuery($username: String = "", $account_id: String = "") {
    policy_manager_v1_ListPolicies(
      input: { account_id: $account_id, username: $username }
    ) {
      policies {
        account_id
        created_at
        description
        file_id
        file_size
        labels
        policy_name
        updated_at
        version
      }
    }
  }
`;

export const listPoliciesNextQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $last_evaluated_policy: api_policy_manager_v1_LastEvaluated_Input = {}
  ) {
    policy_manager_v1_ListPolicies(
      input: {
        account_id: $account_id
        username: $username
        last_evaluated_policy: $last_evaluated_policy
      }
    ) {
      last_evaluated_policy {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      policies {
        account_id
        created_at
        description
        file {
          id
          size
        }
        updated_at
        policy_name
        version
      }
    }
  }
`;

export const getQuestionnaireQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $company_data: api_policy_manager_v1_CompanyData_Input = {}
    $username: String = ""
  ) {
    policy_manager_v1_GetPolicyQuestionnaire(
      input: {
        account_id: $account_id
        company_data: $company_data
        username: $username
      }
    ) {
      questions {
        question
        question_id
        subquestion
      }
    }
  }
`;

export const getCompanyQuestionnaireQuery = gql`
  query MyQuery($username: String = "", $account_id: String = "") {
    policy_manager_v1_GetCompanyDataQuestionnaire(
      input: { account_id: $account_id, username: $username }
    ) {
      schema
      ui_schema
    }
  }
`;

export const submitQuestionnaireMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $answers: JSON = ""
    $username: String = ""
  ) {
    policy_manager_v1_SubmitQuestionnaire(
      input: { account_id: $account_id, answers: $answers, username: $username }
    ) {
      policies
    }
  }
`;

export const deletePolicyMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $policy_name: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_DeletePolicy(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        username: $username
      }
    )
  }
`;

export const createDocumentMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $auto_approval: Boolean = false
    $auto_publish: Boolean = false
    $security_classification: String = ""
    $description: String = ""
    $enable_ai: Boolean = false
    $file_size: BigInt = "0"
    $name: String = ""
    $skip_approval: Boolean = false
    $skip_review: Boolean = false
    $tags: [String] = ""
    $type: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_CreateDocument(
      input: {
        account_id: $account_id
        auto_approval: $auto_approval
        auto_publish: $auto_publish
        security_classification: $security_classification
        enable_ai: $enable_ai
        description: $description
        file_size: $file_size
        skip_approval: $skip_approval
        name: $name
        skip_review: $skip_review
        tags: $tags
        type: $type
        username: $username
      }
    ) {
      signed_urls
      document {
        account_id
        auto_approval
        auto_publish
        security_classification
        created_at
        description
        document_id
        enable_ai
        file_size
        latest
        name
        owner
        s3_version
        scope_tags
        skip_approval
        skip_review
        status
        tags
        type
        updated_at
        version_map
      }
    }
  }
`;

export const updatePolicyMut = gql`
  mutation MyMutation(
    $username: String = ""
    $policy_name: String = ""
    $file_size: BigInt = "0"
    $file_id: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_UpdatePolicy(
      input: {
        account_id: $account_id
        description: $description
        file_id: $file_id
        policy_name: $policy_name
        username: $username
        file_size: $file_size
      }
    ) {
      policy {
        account_id
        created_at
        description
        file_id
        file_size
        labels
        policy_name
        updated_at
        version
      }
      signed_urls
    }
  }
`;

export const getPolicyQuery = gql`
  query MyQuery(
    $username: String = ""
    $policy_name: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_GetPolicy(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        username: $username
      }
    ) {
      policy {
        account_id
        created_at
        description
        file_size
        file_id
        labels
        policy_name
        updated_at
        version
      }
      signed_urls
    }
  }
`;

export const createScopeMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $scope_data: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_CreateScope(
      input: {
        account_id: $account_id
        scope_data: $scope_data
        username: $username
      }
    ) {
      scope {
        account_id
        scope_data
        created_at
        scope_id
        updated_at
      }
    }
  }
`;

export const updateScopeMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $scope_data: String = ""
    $scope_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_UpdateScope(
      input: {
        account_id: $account_id
        scope_data: $scope_data
        scope_id: $scope_id
        username: $username
      }
    ) {
      scope {
        account_id
        created_at
        scope_data
        scope_id
        updated_at
      }
    }
  }
`;

export const deleteScopeMut = gql`
  mutation MyMutation(
    $scope_id: String = ""
    $account_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_DeleteScope(
      input: {
        account_id: $account_id
        scope_id: $scope_id
        username: $username
      }
    )
  }
`;

export const getScopeQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $scope_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_GetScope(
      input: {
        account_id: $account_id
        scope_id: $scope_id
        username: $username
      }
    ) {
      scope {
        account_id
        created_at
        scope_data
        scope_id
        updated_at
      }
    }
  }
`;

export const listRolesQuery = gql`
  query MyQuery(
    $page_size: Int = 10
    $role_name: String = ""
    $username: String = ""
    $page_number: Int = 1
    $include_relations: Boolean = false
    $account_id: String = ""
  ) {
    policy_manager_v1_ListRoles(
      input: {
        username: $username
        role_name: $role_name
        page_size: $page_size
        page_number: $page_number
        include_relations: $include_relations
        account_id: $account_id
      }
    ) {
      end_roles {
        account_id
        created_at
        name
        role_data
        role_id
        updated_at
        username
      }
      relations {
        end_role_id
        start_role_id
        type
      }
      roles {
        account_id
        created_at
        name
        role_data
        role_id
        updated_at
        username
      }
    }
  }
`;

export const listRolesByLastEvaluatedRoleQuery = gql`
  query MyQuery(
    $username: String = ""
    $role_name: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $include_relations: Boolean = false
    $account_id: String = ""
  ) {
    policy_manager_v1_ListRoles(
      input: {
        account_id: $account_id
        include_relations: $include_relations
        page_number: $page_number
        page_size: $page_size
        role_name: $role_name
        username: $username
      }
    ) {
      end_roles {
        account_id
        created_at
        name
        role_data
        role_id
        updated_at
        username
      }
      relations {
        end_role_id
        start_role_id
        type
      }
      roles {
        account_id
        created_at
        name
        role_data
        role_id
        updated_at
        username
      }
    }
  }
`;

export const createRoleRelationMut = gql`
  mutation MyMutation(
    $username: String = ""
    $start_role_id: String = ""
    $end_role_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_CreateRoleRelation(
      input: {
        account_id: $account_id
        end_role_id: $end_role_id
        start_role_id: $start_role_id
        username: $username
      }
    ) {
      relation {
        end_role_id
        start_role_id
        type
      }
    }
  }
`;

export const deleteRoleRelationMut = gql`
  mutation MyMutation(
    $username: String = ""
    $start_role_id: String = ""
    $end_role_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_DeleteRoleRelation(
      input: {
        account_id: $account_id
        end_role_id: $end_role_id
        start_role_id: $start_role_id
        username: $username
      }
    )
  }
`;

export const createRoleMut = gql`
  mutation MyMutation(
    $username: String = ""
    $role_data: String = ""
    $name: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_CreateRole(
      input: {
        account_id: $account_id
        name: $name
        role_data: $role_data
        username: $username
      }
    ) {
      role {
        account_id
        created_at
        name
        role_data
        role_id
        updated_at
        username
      }
    }
  }
`;

export const updateRoleMut = gql`
  mutation MyMutation(
    $username: String = ""
    $role_id: String = ""
    $role_data: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_UpdateRole(
      input: {
        role_data: $role_data
        account_id: $account_id
        role_id: $role_id
        username: $username
      }
    )
  }
`;

export const deleteRoleMut = gql`
  mutation MyMutation(
    $username: String = ""
    $role_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_DeleteRole(
      input: { account_id: $account_id, role_id: $role_id, username: $username }
    )
  }
`;

export const listStatementsQuery = gql`
  query MyQuery($account_id: String = "", $username: String = "") {
    policy_manager_v1_ListStatements(
      input: { account_id: $account_id, username: $username }
    ) {
      last_evaluated_statement {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      statements {
        account_id
        created_at
        evaluated
        opa_query
        statement_data
        statement_id
        version
        updated_at
      }
    }
  }
`;

export const listStatementsByLastEvaluatedStatementQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $last_evaluated_statement: api_policy_manager_v1_LastEvaluated_Input = {}
  ) {
    policy_manager_v1_ListStatements(
      input: {
        account_id: $account_id
        username: $username
        last_evaluated_statement: $last_evaluated_statement
      }
    ) {
      last_evaluated_statement {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      statements {
        account_id
        created_at
        evaluated
        opa_query
        statement_data
        statement_id
        version
        updated_at
      }
    }
  }
`;

export const createStatementDraftMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $evaluated: Boolean = false
    $statement_data: String = ""
    $username: String = ""
    $opa_query: String = ""
  ) {
    policy_manager_v1_CreateStatementDraft(
      input: {
        account_id: $account_id
        evaluated: $evaluated
        statement_data: $statement_data
        username: $username
        opa_query: $opa_query
      }
    ) {
      statement {
        account_id
        created_at
        evaluated
        opa_query
        statement_data
        statement_id
        updated_at
        version
      }
    }
  }
`;

export const updateStatementDraftMut = gql`
  mutation MyMutation(
    $opa_query: String = ""
    $statement_data: String = ""
    $statement_id: String = ""
    $username: String = ""
    $evaluated: Boolean = false
    $account_id: String = ""
  ) {
    policy_manager_v1_UpdateStatementDraft(
      input: {
        account_id: $account_id
        evaluated: $evaluated
        opa_query: $opa_query
        statement_data: $statement_data
        username: $username
        statement_id: $statement_id
      }
    ) {
      statement {
        account_id
        created_at
        evaluated
        opa_query
        statement_data
        statement_id
        updated_at
        version
      }
    }
  }
`;

export const publishStatementMut = gql`
  mutation MyMutation(
    $username: String = ""
    $statement_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_PublishStatement(
      input: {
        account_id: $account_id
        statement_id: $statement_id
        username: $username
      }
    ) {
      status
    }
  }
`;

export const getStatementQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $statement_id: String = ""
    $username: String = ""
    $version: String = ""
  ) {
    policy_manager_v1_GetStatement(
      input: {
        account_id: $account_id
        statement_id: $statement_id
        username: $username
        version: $version
      }
    ) {
      statement {
        account_id
        created_at
        evaluated
        opa_query
        statement_id
        statement_data
        updated_at
        version
      }
    }
  }
`;

export const getStatementDraftQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $statement_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_GetStatementDraft(
      input: {
        account_id: $account_id
        statement_id: $statement_id
        username: $username
      }
    ) {
      statement {
        account_id
        created_at
        evaluated
        opa_query
        statement_data
        statement_id
        updated_at
        version
      }
    }
  }
`;

export const addControlToDocumentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $document_id: String = ""
    $control_id: [String] = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AddControlToDocument(
      input: {
        account_id: $account_id
        control_id: $control_id
        document_id: $document_id
        username: $username
      }
    )
  }
`;

export const addScopeToPolicyMut = gql`
  mutation MyMutation(
    $username: String = ""
    $scope_id: String = ""
    $policy_name: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AddScopeToPolicy(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        scope_id: $scope_id
        username: $username
      }
    ) {
      status
    }
  }
`;

export const addRoleToPolicyMut = gql`
  mutation MyMutation(
    $username: String = ""
    $role_name: String = ""
    $policy_name: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AddRoleToPolicy(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        role_name: $role_name
        username: $username
      }
    ) {
      status
    }
  }
`;

export const removeRoleFromPolicyMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $policy_name: String = ""
    $role_name: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_RemoveRoleFromPolicy(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        role_name: $role_name
        username: $username
      }
    ) {
      status
    }
  }
`;

export const removeScopeFromPolicyMut = gql`
  mutation MyMutation(
    $username: String = ""
    $scope_id: String = ""
    $policy_name: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_RemoveScopeFromPolicy(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        scope_id: $scope_id
        username: $username
      }
    ) {
      status
    }
  }
`;

export const removeControlFromDocumentMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $control_id: [String] = ""
    $document_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_RemoveControlFromDocument(
      input: {
        account_id: $account_id
        control_id: $control_id
        document_id: $document_id
        username: $username
      }
    )
  }
`;

export const listPolicyRolesQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $policy_name: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_ListPolicyRoles(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        username: $username
      }
    ) {
      last_evaluated_role {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      roles {
        account_id
        created_at
        label
        role_data
        role_name
        updated_at
      }
    }
  }
`;

export const listPolicyRolesByLastEvaluatedRoleQuery = gql`
  query MyQuery(
    $policy_name: String = ""
    $username: String = ""
    $account_id: String = ""
    $last_evaluated_role: api_policy_manager_v1_LastEvaluated_Input = {}
  ) {
    policy_manager_v1_ListPolicyRoles(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        username: $username
        last_evaluated_role: $last_evaluated_role
      }
    ) {
      last_evaluated_role {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      roles {
        account_id
        created_at
        label
        role_data
        role_name
        updated_at
      }
    }
  }
`;

export const listPolicyScopesQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $policy_name: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_ListPolicyScopes(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        username: $username
      }
    ) {
      last_evaluated_scope {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      scopes {
        account_id
        created_at
        scope_data
        scope_id
        updated_at
      }
    }
  }
`;
export const listPolicyScopesByLastEvaluatedScopeQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $policy_name: String = ""
    $username: String = ""
    $last_evaluated_scope: api_policy_manager_v1_LastEvaluated_Input = {}
  ) {
    policy_manager_v1_ListPolicyScopes(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        username: $username
        last_evaluated_scope: $last_evaluated_scope
      }
    ) {
      last_evaluated_scope {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      scopes {
        account_id
        created_at
        scope_data
        scope_id
        updated_at
      }
    }
  }
`;

export const listPolicyStatementsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $policy_name: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_ListPolicyStatements(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        username: $username
      }
    ) {
      last_evaluated_statement {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      statements {
        account_id
        created_at
        evaluated
        opa_query
        statement_data
        statement_id
        updated_at
        version
      }
    }
  }
`;

export const listPolicyStatementsByLastEvaluatedStatementQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $policy_name: String = ""
    $username: String = ""
    $last_evaluated_statement: api_policy_manager_v1_LastEvaluated_Input = {}
  ) {
    policy_manager_v1_ListPolicyStatements(
      input: {
        account_id: $account_id
        policy_name: $policy_name
        username: $username
        last_evaluated_statement: $last_evaluated_statement
      }
    ) {
      last_evaluated_statement {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      statements {
        account_id
        created_at
        evaluated
        opa_query
        statement_data
        statement_id
        updated_at
        version
      }
    }
  }
`;

export const getRoleQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $list_relations: Boolean = false
    $role_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_GetRole(
      input: {
        account_id: $account_id
        list_relations: $list_relations
        role_id: $role_id
        username: $username
      }
    ) {
      end_roles {
        account_id
        created_at
        name
        role_data
        role_id
        updated_at
        username
      }
      role {
        account_id
        created_at
        name
        role_data
        role_id
        updated_at
        username
      }
    }
  }
`;

export const listScopesQuery = gql`
  query MyQuery($account_id: String = "", $username: String = "") {
    policy_manager_v1_ListScopes(
      input: { account_id: $account_id, username: $username }
    ) {
      scopes {
        account_id
        created_at
        scope_id
        scope_data
        updated_at
      }
      last_evaluated_scope {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
    }
  }
`;

export const listScopesByLastEvaluatedScopeQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $last_evaluated_scope: api_policy_manager_v1_LastEvaluated_Input = {}
  ) {
    policy_manager_v1_ListScopes(
      input: {
        account_id: $account_id
        username: $username
        last_evaluated_scope: $last_evaluated_scope
      }
    ) {
      scopes {
        account_id
        created_at
        scope_id
        scope_data
        updated_at
      }
      last_evaluated_scope {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
    }
  }
`;

export const listDocumentsQuery = gql`
  query MyQuery(
    $username: String = ""
    $types: [String] = ""
    $tags: [String] = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $name: String = ""
    $filter_by: JSON = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_ListDocuments(
      input: {
        account_id: $account_id
        filter_by: $filter_by
        name: $name
        page_number: $page_number
        page_size: $page_size
        tags: $tags
        types: $types
        username: $username
      }
    ) {
      documents {
        account_id
        auto_approval
        auto_publish
        created_at
        description
        document_id
        enable_ai
        file_size
        latest
        name
        owner
        s3_version
        scope_tags
        security_classification
        skip_review
        skip_approval
        status
        tags
        type
        updated_at
        version_map
      }
    }
  }
`;

export const listControlsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $draft: Boolean = false
    $ds_managed: Boolean = false
    $latest: Boolean = false
    $page_number: Int = 1
    $page_size: Int = 10
    $username: String = ""
  ) {
    policy_manager_v1_ListControls(
      input: {
        username: $username
        page_size: $page_size
        latest: $latest
        ds_managed: $ds_managed
        draft: $draft
        account_id: $account_id
        page_number: $page_number
      }
    ) {
      controls {
        account_id
        control_data
        control_id
        created_at
        evaluated
        latest_version
        opa_query
        owner
        updated_at
        version
      }
    }
  }
`;

export const getUsageQuery = gql`
  query MyQuery($username: String = "", $account_id: String = "") {
    policy_manager_v1_GetUsage(
      input: { account_id: $account_id, username: $username }
    ) {
      account_id
      control_count
      document_count
      personnel_count
      role_count
      unfilled_role_count
    }
  }
`;

export const getControlQuery = gql`
  query MyQuery(
    $control_id: String = ""
    $account_id: String = ""
    $username: String = ""
    $version: Int = 10
    $draft: Boolean = false
    $ds_managed: Boolean = false
  ) {
    policy_manager_v1_GetControl(
      input: {
        version: $version
        username: $username
        draft: $draft
        account_id: $account_id
        control_id: $control_id
        ds_managed: $ds_managed
      }
    ) {
      control {
        account_id
        control_data
        control_id
        created_at
        evaluated
        opa_query
        owner
        updated_at
        version
      }
    }
  }
`;

export const getDocumentQuery = gql`
  query MyQuery(
    $version: Int = 1
    $username: String = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_GetDocument(
      input: {
        account_id: $account_id
        document_id: $document_id
        username: $username
        version: $version
      }
    ) {
      signed_urls
      document {
        tags
        account_id
        auto_approval
        auto_publish
        security_classification
        created_at
        description
        document_id
        enable_ai
        file_size
        latest
        name
        owner
        s3_version
        scope_tags
        skip_approval
        skip_review
        status
        type
        updated_at
        version_map
      }
    }
  }
`;

export const listDocumentControlsQuery = gql`
  query MyQuery(
    $username: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_ListDocumentControls(
      input: {
        account_id: $account_id
        document_id: $document_id
        page_number: $page_number
        page_size: $page_size
        username: $username
      }
    ) {
      controls {
        account_id
        control_data
        control_id
        created_at
        evaluated
        opa_query
        owner
        updated_at
        version
      }
    }
  }
`;

export const listDocumentRolesQuery = gql`
  query MyQuery(
    $username: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_ListDocumentRoles(
      input: {
        account_id: $account_id
        document_id: $document_id
        page_number: $page_number
        page_size: $page_size
        username: $username
      }
    ) {
      roles {
        account_id
        created_at
        name
        role_data
        role_id
        updated_at
        username
      }
    }
  }
`;

export const listDocumentSupervisorsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $document_id: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $supervisor_type: String = ""
  ) {
    policy_manager_v1_ListSupervisors(
      input: {
        account_id: $account_id
        document_id: $document_id
        page_number: $page_number
        page_size: $page_size
        supervisor_type: $supervisor_type
        username: $username
      }
    ) {
      supervisors {
        acknowledged
        created_at
        email
        first_name
        last_name
        supervisor_id
        type
        updated_at
      }
    }
  }
`;

export const updateDocumentMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $auto_approval: Boolean = false
    $auto_publish: Boolean = false
    $description: String = ""
    $document_id: String = ""
    $enable_ai: Boolean = false
    $file_size: BigInt = "0"
    $name: String = ""
    $return_url: Boolean = false
    $s3_version: String = ""
    $skip_approval: Boolean = false
    $skip_review: Boolean = false
    $username: String = ""
  ) {
    policy_manager_v1_UpdateDocument(
      input: {
        account_id: $account_id
        auto_approval: $auto_approval
        auto_publish: $auto_publish
        description: $description
        document_id: $document_id
        enable_ai: $enable_ai
        file_size: $file_size
        name: $name
        return_url: $return_url
        s3_version: $s3_version
        skip_approval: $skip_approval
        skip_review: $skip_review
        username: $username
      }
    ) {
      document {
        version_map
        updated_at
        type
        tags
        status
        skip_review
        skip_approval
        scope_tags
        s3_version
        owner
        name
        latest
        file_size
        enable_ai
        document_id
        description
        created_at
        security_classification
        auto_publish
        auto_approval
        account_id
      }
      signed_urls
    }
  }
`;

export const createControlMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $control_data: String = ""
    $evaluated: Boolean = false
    $opa_query: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_CreateControlDraft(
      input: {
        account_id: $account_id
        control_data: $control_data
        evaluated: $evaluated
        opa_query: $opa_query
        username: $username
      }
    ) {
      control {
        account_id
        control_data
        control_id
        evaluated
        created_at
        opa_query
        owner
        updated_at
        version
      }
    }
  }
`;

export const updateControlDraftMut = gql`
  mutation MyMutation(
    $username: String = ""
    $opa_query: String = ""
    $evaluated: Boolean = false
    $control_id: String = ""
    $control_data: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_UpdateControlDraft(
      input: {
        account_id: $account_id
        control_data: $control_data
        control_id: $control_id
        evaluated: $evaluated
        opa_query: $opa_query
        username: $username
      }
    )
  }
`;

export const sendForReviewMut = gql`
  mutation MyMutation(
    $username: String = ""
    $document_name: String = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_SendForReview(
      input: {
        account_id: $account_id
        document_id: $document_id
        document_name: $document_name
        username: $username
      }
    )
  }
`;

export const addSupervisorsMut = gql`
  mutation MyMutation(
    $supervisors: [api_policy_manager_v1_Supervisor_Input] = {}
    $document_id: String = ""
    $account_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_AddSupervisors(
      input: {
        account_id: $account_id
        document_id: $document_id
        supervisors: $supervisors
        username: $username
      }
    ) {
      document_status
      supervisors {
        acknowledged
        created_at
        email
        first_name
        last_name
        supervisor_id
        type
        updated_at
      }
    }
  }
`;

export const sendForApprovalMut = gql`
  mutation MyMutation(
    $username: String = ""
    $document_id: String = ""
    $account_id: String = ""
    $document_name: String = ""
  ) {
    policy_manager_v1_SendForApproval(
      input: {
        account_id: $account_id
        document_id: $document_id
        username: $username
        document_name: $document_name
      }
    )
  }
`;

export const createPersonnalMut = gql`
  mutation MyMutation(
    $props: JSON = ""
    $position: String = ""
    $user_type: String = ""
    $username: String = ""
    $last_name: String = ""
    $first_name: String = ""
    $email: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_CreatePersonnel(
      input: {
        account_id: $account_id
        email: $email
        first_name: $first_name
        position: $position
        last_name: $last_name
        props: $props
        user_type: $user_type
        username: $username
      }
    ) {
      personnel {
        created_at
        email
        first_name
        last_name
        personnel_id
        position
        props
        updated_at
        user_type
      }
    }
  }
`;

export const listPesonnalQuery = gql`
  query MyQuery(
    $username: String = ""
    $search: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $include_relations: Boolean = false
    $account_id: String = ""
  ) {
    policy_manager_v1_ListPersonnels(
      input: {
        account_id: $account_id
        include_relations: $include_relations
        page_number: $page_number
        page_size: $page_size
        search: $search
        username: $username
      }
    ) {
      managers {
        created_at
        email
        first_name
        last_name
        personnel_id
        position
        props
        updated_at
        user_type
      }
      personnels {
        created_at
        email
        first_name
        last_name
        personnel_id
        position
        props
        updated_at
        user_type
      }
      relations {
        end_id
        start_id
      }
    }
  }
`;

export const getPersonnelQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $personnel_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_GetPersonnel(
      input: {
        account_id: $account_id
        personnel_id: $personnel_id
        username: $username
      }
    ) {
      personnel {
        created_at
        email
        first_name
        last_name
        personnel_id
        position
        props
        updated_at
        user_type
      }
    }
  }
`;

export const deletePersonnalMut = gql`
  mutation MyMutation(
    $username: String = ""
    $personnel_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_DeletePersonnel(
      input: {
        account_id: $account_id
        personnel_id: $personnel_id
        username: $username
      }
    )
  }
`;

export const updatePersonnalMut = gql`
  mutation MyMutation(
    $username: String = ""
    $user_type: String = ""
    $props: JSON = ""
    $position: String = ""
    $personnel_id: String = ""
    $last_name: String = ""
    $first_name: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_UpdatePersonnel(
      input: {
        account_id: $account_id
        first_name: $first_name
        last_name: $last_name
        personnel_id: $personnel_id
        position: $position
        user_type: $user_type
        props: $props
        username: $username
      }
    )
  }
`;

export const publishDocumentMut = gql`
  mutation MyMutation(
    $effective_date: String = ""
    $document_id: String = ""
    $account_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_PublishDocument(
      input: {
        effective_date: $effective_date
        document_id: $document_id
        account_id: $account_id
        username: $username
      }
    ) {
      status
    }
  }
`;

export const deleteDocumentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_DeleteDocument(
      input: {
        account_id: $account_id
        document_id: $document_id
        username: $username
      }
    )
  }
`;

export const updateDocumentLifecycleMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $approvers: [String] = ""
    $auto_approval: Boolean = false
    $auto_publish: Boolean = false
    $document_name: String = ""
    $reviewers: [String] = ""
    $username: String = ""
  ) {
    policy_manager_v1_UpdateLifecycleDetails(
      input: {
        account_id: $account_id
        approvers: $approvers
        auto_approval: $auto_approval
        auto_publish: $auto_publish
        document_name: $document_name
        reviewers: $reviewers
        username: $username
      }
    )
  }
`;

export const updateControlMut = gql`
  mutation MyMutation(
    $username: String = ""
    $update_labels: [String] = ""
    $opa_query: String = ""
    $evaluated: Boolean = false
    $control_id: String = ""
    $control_data: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_UpdateControlDraft(
      input: {
        account_id: $account_id
        control_data: $control_data
        control_id: $control_id
        evaluated: $evaluated
        opa_query: $opa_query
        update_labels: $update_labels
        username: $username
      }
    )
  }
`;

export const getDocumentLifecycleDetailsQuery = gql`
  query MyQuery(
    $username: String = ""
    $document_name: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_GetLifecycleDetails(
      input: {
        account_id: $account_id
        document_name: $document_name
        username: $username
      }
    ) {
      approvers
      auto_approval
      auto_publish
      reviewers
    }
  }
`;

export const reviewDocumentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $reviewer_email: String = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_ReviewDocument(
      input: {
        account_id: $account_id
        document_id: $document_id
        reviewer_email: $reviewer_email
        username: $username
      }
    ) {
      status
    }
  }
`;

export const approveDocumentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $document_id: String = ""
    $approver_email: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_ApproveDocument(
      input: {
        account_id: $account_id
        approver_email: $approver_email
        document_id: $document_id
        username: $username
      }
    ) {
      status
    }
  }
`;

export const publishControlMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $control_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_PublishControl(
      input: {
        account_id: $account_id
        control_id: $control_id
        username: $username
      }
    ) {
      status
      latest_version
    }
  }
`;

export const getDocumentDraftMut = gql`
  query MyQuery(
    $account_id: String = ""
    $document_id: String = ""
    $username: String = ""
    $return_url: Boolean = false
  ) {
    policy_manager_v1_GetDocumentDraft(
      input: {
        account_id: $account_id
        document_id: $document_id
        return_url: $return_url
        username: $username
      }
    ) {
      document {
        account_id
        auto_approval
        auto_publish
        security_classification
        created_at
        description
        document_id
        enable_ai
        file_size
        latest
        name
        owner
        s3_version
        scope_tags
        skip_approval
        skip_review
        status
        tags
        type
        updated_at
        version_map
      }
      signed_urls
    }
  }
`;

export const removeDocumentSupervisorsMut = gql`
  mutation MyMutation(
    $supervisors: [api_policy_manager_v1_Supervisor_Input] = {}
    $username: String = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_RemoveSupervisors(
      input: {
        account_id: $account_id
        document_id: $document_id
        supervisors: $supervisors
        username: $username
      }
    ) {
      supervisors {
        acknowledged
        created_at
        email
        first_name
        last_name
        supervisor_id
        type
        updated_at
      }
    }
  }
`;

export const listPersonnelAttachementsQuery = gql`
  query MyQuery(
    $username: String = ""
    $type: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $filter_by: JSON = ""
    $email: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_ListPersonnelAttachments(
      input: {
        email: $email
        account_id: $account_id
        filter_by: $filter_by
        page_number: $page_number
        page_size: $page_size
        type: $type
        username: $username
      }
    ) {
      documents {
        account_id
        auto_approval
        auto_publish
        created_at
        document_id
        description
        enable_ai
        file_size
        latest
        name
        owner
        s3_version
        scope_tags
        status
        skip_approval
        skip_review
        type
        updated_at
        version_map
      }
      roles {
        account_id
        created_at
        role_data
        role_name
        updated_at
        username
      }
    }
  }
`;

export const deleteControlMut = gql`
  mutation MyMutation(
    $username: String = ""
    $control_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_DeleteControl(
      input: {
        account_id: $account_id
        control_id: $control_id
        username: $username
      }
    )
  }
`;

export const addScopeTagToDocumentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $scope_tags: [String] = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AddScopeTags(
      input: {
        account_id: $account_id
        document_id: $document_id
        scope_tags: $scope_tags
        username: $username
      }
    ) {
      message
    }
  }
`;

export const removeScopeTagFromDocumentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $scope_tags: [String] = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_RemoveScopeTags(
      input: {
        account_id: $account_id
        document_id: $document_id
        scope_tags: $scope_tags
        username: $username
      }
    )
  }
`;

export const listNonCompliantUsersQuery = gql`
  query MyQuery(
    $username: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $date: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_ListNonCompliantPersonnels(
      input: {
        account_id: $account_id
        date: $date
        page_number: $page_number
        page_size: $page_size
        username: $username
      }
    ) {
      non_compliant_personnels {
        document_id
        email
        first_name
        last_name
        name
        personnel_id
        position
        type
        updated_at
        user_type
      }
    }
  }
`;

export const createTemplateMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $control_ids: [String] = ""
    $file_size: BigInt = "0"
    $name: String = ""
    $role_ids: [String] = ""
    $scope_tags: [String] = ""
    $security_classification: String = ""
    $type: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_CreateTemplate(
      input: {
        account_id: $account_id
        control_ids: $control_ids
        file_size: $file_size
        name: $name
        role_ids: $role_ids
        scope_tags: $scope_tags
        security_classification: $security_classification
        type: $type
        username: $username
      }
    ) {
      signed_urls
      template_id
    }
  }
`;

export const updateTemplateMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $control_ids: [String] = ""
    $file_size: BigInt = "0"
    $return_url: Boolean = false
    $role_ids: [String] = ""
    $scope_tags: [String] = ""
    $security_classification: String = ""
    $template_id: String = ""
    $type: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_UpdateTemplate(
      input: {
        account_id: $account_id
        control_ids: $control_ids
        file_size: $file_size
        return_url: $return_url
        role_ids: $role_ids
        scope_tags: $scope_tags
        security_classification: $security_classification
        template_id: $template_id
        type: $type
        username: $username
      }
    ) {
      signed_urls
    }
  }
`;

export const deleteTemplateMut = gql`
  mutation MyMutation(
    $username: String = ""
    $template_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_DeleteTemplate(
      input: {
        account_id: $account_id
        template_id: $template_id
        username: $username
      }
    )
  }
`;

export const listTemplatesQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $ds_managed: Boolean = false
    $name: String = ""
    $page_size: Int = 10
    $username: String = ""
  ) {
    policy_manager_v1_ListTemplates(
      input: {
        account_id: $account_id
        ds_managed: $ds_managed
        name: $name
        page_size: $page_size
        username: $username
      }
    ) {
      last_evaluated_template {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      templates {
        account_id
        control_ids
        created_at
        file_size
        name
        role_ids
        scope_tags
        security_classification
        template_id
        type
        updated_at
        username
      }
    }
  }
`;

export const listTemplatesByLastEvaluatedTemplatedQuery = gql`
  query MyQuery(
    $name: String = ""
    $page_size: Int = 10
    $username: String = ""
    $ds_managed: Boolean = false
    $account_id: String = ""
    $last_evaluated_template: api_policy_manager_v1_LastEvaluated_Input = {}
  ) {
    policy_manager_v1_ListTemplates(
      input: {
        account_id: $account_id
        ds_managed: $ds_managed
        page_size: $page_size
        username: $username
        name: $name
        last_evaluated_template: $last_evaluated_template
      }
    ) {
      last_evaluated_template {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      templates {
        account_id
        control_ids
        created_at
        file_size
        name
        role_names
        scope_tags
        security_classification
        type
        updated_at
        username
      }
    }
  }
`;

export const getTemplateQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $ds_managed: Boolean = false
    $template_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_GetTemplate(
      input: {
        account_id: $account_id
        ds_managed: $ds_managed
        template_id: $template_id
        username: $username
      }
    ) {
      signed_urls
      template {
        account_id
        control_ids
        created_at
        file_size
        name
        role_ids
        scope_tags
        security_classification
        template_id
        type
        updated_at
        username
      }
    }
  }
`;

export const generateDocumentsMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $document_names: [String] = ""
    $enable_ai: Boolean = false
    $supervisors: [api_policy_manager_v1_Supervisor_Input] = {}
    $username: String = ""
    $custom_template: Boolean = false
  ) {
    policy_manager_v1_GenerateDocuments(
      input: {
        custom_template: $custom_template
        account_id: $account_id
        document_names: $document_names
        enable_ai: $enable_ai
        supervisors: $supervisors
        username: $username
      }
    ) {
      redis_key
      status
    }
  }
`;

export const getGeneratePolicyStatusQuery = gql`
  query MyQuery(
    $username: String = ""
    $redis_key: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_GetGeneratePolicyStatus(
      input: {
        account_id: $account_id
        redis_key: $redis_key
        username: $username
      }
    ) {
      generated_documents
      status
    }
  }
`;

export const removeTagsMut = gql`
  mutation MyMutation(
    $username: String = ""
    $tags: [String] = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_RemoveTags(
      input: {
        account_id: $account_id
        document_id: $document_id
        username: $username
        tags: $tags
      }
    )
  }
`;

export const addTagsMut = gql`
  mutation MyMutation(
    $username: String = ""
    $tags: [String] = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AddTags(
      input: {
        account_id: $account_id
        document_id: $document_id
        tags: $tags
        username: $username
      }
    )
  }
`;

export const getSettingsQuery = gql`
  query MyQuery($username: String = "", $account_id: String = "") {
    policy_manager_v1_GetSettings(
      input: { account_id: $account_id, username: $username }
    ) {
      settings {
        company_data {
          company_name
          company_type
          location
          other_data
          security_email
        }
        default_settings
        enable_ai
        supervisors {
          acknowledged
          created_at
          email
          first_name
          last_name
          type
          updated_at
        }
      }
    }
  }
`;

export const updateSettingsMut = gql`
  mutation MyMutation(
    $supervisors: [api_policy_manager_v1_Supervisor_Input] = {}
    $enable_ai: Boolean = false
    $company_data: api_policy_manager_v1_CompanyData_Input = {}
    $account_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_UpdateSettings(
      input: {
        account_id: $account_id
        enable_ai: $enable_ai
        company_data: $company_data
        supervisors: $supervisors
        username: $username
      }
    ) {
      settings {
        company_data {
          company_name
          company_type
          location
          other_data
          security_email
        }
        default_settings
        enable_ai
        supervisors {
          acknowledged
          created_at
          email
          first_name
          last_name
          type
          updated_at
        }
      }
    }
  }
`;

export const updateDocumentClassificationMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $security_classification: String = ""
    $document_id: String = ""
    $old_classification: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_UpdateDocumentClassification(
      input: {
        account_id: $account_id
        security_classification: $security_classification
        document_id: $document_id
        old_classification: $old_classification
        username: $username
      }
    )
  }
`;

export const addManagerToPersonnelMut = gql`
  mutation MyMutation(
    $username: String = ""
    $personnel_id: String = ""
    $manager_id: [String] = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AddManagerToPersonnel(
      input: {
        account_id: $account_id
        manager_id: $manager_id
        personnel_id: $personnel_id
        username: $username
      }
    )
  }
`;

export const removeManagerFromPersonnelMut = gql`
  mutation MyMutation(
    $username: String = ""
    $personnel_id: String = ""
    $manager_id: [String] = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_RemoveManagerFromPersonnel(
      input: {
        account_id: $account_id
        manager_id: $manager_id
        personnel_id: $personnel_id
        username: $username
      }
    )
  }
`;

export const addRoleToPersonnelMut = gql`
  mutation MyMutation(
    $username: String = ""
    $role_id: [String] = ""
    $personnel_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AddRoleToPersonnel(
      input: {
        account_id: $account_id
        personnel_id: $personnel_id
        role_id: $role_id
        username: $username
      }
    )
  }
`;

export const removeRoleFromPersonnelMut = gql`
  mutation MyMutation(
    $username: String = ""
    $role_id: [String] = ""
    $personnel_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_RemoveRoleFromPersonnel(
      input: {
        account_id: $account_id
        personnel_id: $personnel_id
        role_id: $role_id
        username: $username
      }
    )
  }
`;

export const listPersonnelRolesAndManagersQuery = gql`
  query MyQuery(
    $username: String = ""
    $personnel_id: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $filter_by: JSON = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_ListPersonnelRoles(
      input: {
        account_id: $account_id
        filter_by: $filter_by
        page_number: $page_number
        page_size: $page_size
        personnel_id: $personnel_id
        username: $username
      }
    ) {
      roles {
        account_id
        created_at
        name
        role_data
        role_id
        updated_at
        username
      }
    }
    policy_manager_v1_ListPersonnelManagers(
      input: {
        account_id: $account_id
        filter_by: $filter_by
        page_number: $page_number
        page_size: $page_size
        personnel_id: $personnel_id
        username: $username
      }
    ) {
      managers {
        created_at
        email
        first_name
        last_name
        personnel_id
        position
        props
        updated_at
        user_type
      }
    }
  }
`;

export const removeRoleFromDocumentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $role_id: [String] = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_RemoveRoleFromDocument(
      input: {
        account_id: $account_id
        document_id: $document_id
        role_id: $role_id
        username: $username
      }
    )
  }
`;

export const addRoleToDocumentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $role_id: [String] = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AddRoleToDocument(
      input: {
        account_id: $account_id
        document_id: $document_id
        username: $username
        role_id: $role_id
      }
    )
  }
`;

export const optInMut = gql`
  mutation MyMutation($account_id: String = "", $username: String = "") {
    policy_manager_v1_OptIn(
      input: { account_id: $account_id, username: $username }
    ) {
      redirect_url
    }
  }
`;

export const optOutMut = gql`
  mutation MyMutation($username: String = "", $account_id: String = "") {
    policy_manager_v1_OptOut(
      input: { account_id: $account_id, username: $username }
    ) {
      redirect_url
    }
  }
`;

export const addDocumentToAIMut = gql`
  mutation MyMutation(
    $username: String = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AddDocumentToAI(
      input: {
        account_id: $account_id
        document_id: $document_id
        username: $username
      }
    )
  }
`;

export const removeDocumentFromAIMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $document_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_RemoveAIFromDocument(
      input: {
        account_id: $account_id
        document_id: $document_id
        username: $username
      }
    )
  }
`;

export const listPublishedDocumentsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $filter_by: JSON = ""
    $page_number: Int = 1
    $name: String = ""
    $page_size: Int = 10
    $tags: [String] = ""
    $types: [String] = ""
    $username: String = ""
  ) {
    policy_manager_v1_ListPublishedDocuments(
      input: {
        account_id: $account_id
        filter_by: $filter_by
        page_number: $page_number
        name: $name
        page_size: $page_size
        tags: $tags
        types: $types
        username: $username
      }
    ) {
      documents {
        account_id
        auto_publish
        auto_approval
        created_at
        description
        document_id
        enable_ai
        file_size
        latest
        name
        owner
        s3_version
        scope_tags
        security_classification
        skip_approval
        skip_review
        status
        tags
        type
        updated_at
        version_map
      }
    }
  }
`;

export const listPersonnelDocumentsQuery = gql`
  query MyQuery($account_id: String = "", $acknowledged: Boolean = false, $page_number: Int = 1, $page_size: Int = 10, $personnel_id: String = "", $username: String = "") {
  policy_manager_v1_ListPersonnelDocuments(
    input: {account_id: $account_id, acknowledged: $acknowledged, page_number: $page_number, page_size: $page_size, personnel_id: $personnel_id, username: $username}
  ) {
    documents {
      acknowledged
      document_id
      email
      first_name
      last_name
      name
      personnel_id
      position
      type
      updated_at
      user_type
    }
  }
}
`;

export const createAssessmentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $name: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_CreateAssessment(
      input: {
        account_id: $account_id
        description: $description
        name: $name
        username: $username
      }
    ) {
      assessment {
        answered_questions
        assessment_id
        created_at
        name
        description
        status
        total_questions
        updated_at
      }
    }
  }
`;

export const updateAssessmentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $name: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_UpdateAssessment(
      input: {
        account_id: $account_id
        description: $description
        name: $name
        username: $username
      }
    )
  }
`;

export const deleteAssessmentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $assessment_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_DeleteAssessment(
      input: {
        account_id: $account_id
        username: $username
        assessment_id: $assessment_id
      }
    )
  }
`;

export const addQuestionsToAssessmentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $questions: [String] = ""
    $assessment_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AddQuestionsToAssessment(
      input: {
        account_id: $account_id
        assessment_id: $assessment_id
        questions: $questions
        username: $username
      }
    )
  }
`;

export const removeQuestionFromAssessmentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $question_ids: [String] = ""
    $assessment_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_RemoveQuestionsFromAssessment(
      input: {
        account_id: $account_id
        assessment_id: $assessment_id
        question_ids: $question_ids
        username: $username
      }
    )
  }
`;

export const getAssessmentQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $assessment_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_GetAssessment(
      input: {
        account_id: $account_id
        assessment_id: $assessment_id
        username: $username
      }
    ) {
      assessment {
        answered_questions
        assessment_id
        created_at
        description
        name
        status
        total_questions
        updated_at
      }
    }
  }
`;

export const listAssessmentsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $name: String = ""
    $page_size: Int = 10
    $username: String = ""
  ) {
    policy_manager_v1_ListAssessments(
      input: {
        account_id: $account_id
        name: $name
        page_size: $page_size
        username: $username
      }
    ) {
      assessments {
        answered_questions
        assessment_id
        created_at
        description
        name
        status
        total_questions
        updated_at
      }
      last_evaluated_assessment {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
    }
  }
`;

export const listAssessmentQueryByLastEvaluatedAssessment = gql`
  query MyQuery(
    $account_id: String = ""
    $name: String = ""
    $page_size: Int = 10
    $username: String = ""
    $last_evaluated_assessment: api_policy_manager_v1_LastEvaluated_Input = {}
  ) {
    policy_manager_v1_ListAssessments(
      input: {
        account_id: $account_id
        name: $name
        page_size: $page_size
        username: $username
        last_evaluated_assessment: $last_evaluated_assessment
      }
    ) {
      assessments {
        answered_questions
        assessment_id
        created_at
        description
        name
        status
        total_questions
        updated_at
      }
      last_evaluated_assessment {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
    }
  }
`;

export const listAssessmentQuestionsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $assessment_id: String = ""
    $page_size: Int = 10
    $username: String = ""
  ) {
    policy_manager_v1_ListAssessmentQuestions(
      input: {
        account_id: $account_id
        assessment_id: $assessment_id
        page_size: $page_size
        username: $username
      }
    ) {
      last_evaluated_assessment_question {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      questons {
        answer
        answered
        created_at
        question
        question_id
        related_documents
        status
        updated_at
      }
    }
  }
`;

export const listAssessmentQuestionsQueryByLastEvaluatedAssessmentQuestion = gql`
  query MyQuery(
    $account_id: String = ""
    $assessment_id: String = ""
    $page_size: Int = 10
    $username: String = ""
    $last_evaluated_assessment_question: api_policy_manager_v1_LastEvaluated_Input = {

    }
  ) {
    policy_manager_v1_ListAssessmentQuestions(
      input: {
        account_id: $account_id
        assessment_id: $assessment_id
        page_size: $page_size
        username: $username
        last_evaluated_assessment_question: $last_evaluated_assessment_question
      }
    ) {
      last_evaluated_assessment_question {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      questons {
        answer
        answered
        created_at
        question
        question_id
        related_documents
        status
        updated_at
      }
    }
  }
`;

export const getEmployeeDashboardDataQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $personnel_id: String = ""
    $username: String = ""
  ) {
    policy_manager_v1_ListPersonnelDocuments(
      input: {
        account_id: $account_id
        page_size: 5
        personnel_id: $personnel_id
        username: $username
      }
    ) {
      documents {
        acknowledged
        document_id
        email
        first_name
        last_name
        name
        personnel_id
        position
        type
        updated_at
        user_type
      }
    }
    policy_manager_v1_ListPersonnelRoles(
      input: {
        page_size: 5
        account_id: $account_id
        username: $username
        personnel_id: $personnel_id
      }
    ) {
      roles {
        account_id
        created_at
        name
        role_data
        role_id
        updated_at
        username
      }
    }
    policy_manager_v1_ListPublishedDocuments(
      input: { account_id: $account_id, username: $username, page_size: 10 }
    ) {
      documents {
        account_id
        auto_approval
        auto_publish
        created_at
        description
        enable_ai
        document_id
        file_size
        latest
        name
        owner
        s3_version
        scope_tags
        security_classification
        skip_approval
        skip_review
        status
        tags
        type
        updated_at
        version_map
      }
    }
  }
`;

export const generateRolesMut = gql`
  mutation MyMutation($account_id: String = "", $username: String = "") {
    policy_manager_v1_GenerateRoles(
      input: { account_id: $account_id, username: $username }
    ) {
      status_key
    }
  }
`;

export const acknowledgeDocumentMut = gql`
  mutation MyMutation(
    $username: String = ""
    $personnel_id: String = ""
    $document_id: String = ""
    $account_id: String = ""
  ) {
    policy_manager_v1_AcknowledgeDocument(
      input: {
        account_id: $account_id
        document_id: $document_id
        personnel_id: $personnel_id
        username: $username
      }
    ) {
      status
    }
  }
`;
