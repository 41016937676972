import {
  sendQueryRequest,
  sendMutationRequest,
  sendMutationRequestWithIP,
  sendQueryRequestWithIP,
} from "./apollo-client";
import {
  accessDropzoneMut,
  accessDropzoneWithoutPasswordMut,
  createDropzoneMut,
  createResponseMut,
  createResponseWithLimitMut,
  createRootKeyMut,
  createShareMut,
  createTemplateMut,
  dashboardQuery,
  deleteTemplateMut,
  getDashboardDataQuery,
  getDropzoneQuery,
  getRequestQuery,
  getResponseQuery,
  getSendAccessHistoryByUsernameByLastEvaluatedAccessHistoryQuery,
  getSendAccessHistoryByUsernameQuery,
  getSendByLastEvaluatedAccessHistoryQuery,
  getSendQuery,
  getTemplateQuery,
  getTemplatesQuery,
  listDropzonesQuery,
  listResponsesQuery,
  listResponsesWithLastEvaluatedResponseQuery,
  notificationsQuery,
  notificationsQueryByLastNotificationQuery,
  postByStatsQuery,
  postsByUsernameQuery,
  postsByUsernameWithLastRecordQuery,
  readRequestedDataQuery,
  readRequestQuery,
  readRootKeyQuery,
  requestDataMut,
  requestHistoryByLastEvalutedPostQuery,
  requestHistoryQuery,
  submitRequestDataMut,
  updateDropzoneMut,
  updateRequestMut,
  updateRootKeyMut,
  updateTemplateMut,
  viewSecuredDataQuery,
  dropzoneResponsesByLastEvaluatedRepsonseQuery,
  dropzoneResponsesQuery,
  listDropzonesByLastEvaluatedDropzoneQuery,
  dropzoneResponsesWithDropzoneKeyQuery,
  dropzoneResponsesWithDropzoneKeyByLastEvaluatedRepsonseQuery,
  listResponsesByRespondentQuery,
  listResponsesByRespondentByLastEvaluatedResponseQuery,
  updateSendMut,
  expireSendMut,
} from "../graphql/secure-share";

export const createShareAndGetFileNames = (
  access_device,
  account_id,
  access_limit,
  download_allowed,
  encrypted_data,
  end_date,
  files,
  hide_stats,
  hide_identity,
  start_date,
  password_set,
  login_required,
  username,
  label
) => {
  const variables = {
    access_device: access_device,
    account_id: account_id,
    access_limit: access_limit,
    download_allowed: download_allowed,
    encrypted_data: encrypted_data,
    end_date: end_date,
    files: files,
    hide_stats: hide_stats,
    hide_identity: hide_identity,
    start_date: start_date,
    password_set: password_set,
    login_required: login_required,
    username: username,
    label: label,
  };
  return sendMutationRequest({
    mutation: createShareMut,
    variables: variables,
  });
};

export function uploadFile(url, file, headers = null) {
  let headerObj = {
    "Content-Type": file?.type,
  };
  if (headers) {
    headerObj = { ...headerObj, ...headers };
  }
  return fetch(url, {
    method: "PUT",
    headers: headerObj,
    body: file,
  }).catch((err) => {
    console.log(err);
    throw err;
  });
}

export const getSecuredData = (send_id, recaptcha_code, extraParams = {}) => {
  return sendMutationRequestWithIP({
    mutation: viewSecuredDataQuery,
    variables: {
      send_id: send_id,
      recaptcha_code: recaptcha_code,
      ...extraParams,
    },
  });
};

export const getSend = (send_id, account_id, username) => {
  return sendQueryRequestWithIP({
    query: getSendQuery,
    variables: {
      source_id: send_id,
      account_id: account_id,
      username: username,
    },
    fetchPolicy: "network-only",
  });
};

export const getSendByLastEvaluatedAccessHistory = (
  send_id,
  account_id,
  username,
  last_evaluated_access_history
) => {
  return sendQueryRequestWithIP({
    query: getSendByLastEvaluatedAccessHistoryQuery,
    variables: {
      source_id: send_id,
      account_id: account_id,
      username: username,
      last_evaluated_access_history: last_evaluated_access_history,
    },
  });
};

export const getDashboardData = (
  account_id,
  username,
  start_date,
  end_date,
  month_year
) => {
  return sendQueryRequest({
    query: getDashboardDataQuery,
    variables: {
      account_id: account_id,
      username: username,
      month_year: month_year,
      start_date: start_date,
      end_date: end_date,
    },
    fetchPolicy: "network-only",
  });
};

export const getPostsByUsername = (
  account_id,
  username,
  start_date = "",
  end_date = ""
) => {
  return sendQueryRequest({
    query: postsByUsernameQuery,
    variables: {
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
    },
    fetchPolicy: "network-only",
  });
};

export const getPostsByUsernameByLastRecord = (
  account_id,
  username,
  last_evaluated_send,
  start_date = "",
  end_date = ""
) => {
  return sendQueryRequest({
    query: postsByUsernameWithLastRecordQuery,
    variables: {
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
      last_evaluated_send: last_evaluated_send,
    },
    fetchPolicy: "network-only",
  });
};

export const getPostByStats = (
  account_id,
  username,
  uuid,
  start_date = "",
  end_date = ""
) => {
  return sendQueryRequest({
    query: postByStatsQuery,
    variables: {
      account_id: account_id,
      username: username,
      uuid: uuid,
      start_date: start_date,
      end_date: end_date,
    },
    fetchPolicy: "network-only",
  });
};

export const requestDataRequest = (input) => {
  return sendMutationRequest({ mutation: requestDataMut, variables: input });
};

export const readRequestRequest = (request_id, recaptcha_code, extraParams = {}) => {
  return sendMutationRequestWithIP({
    mutation: readRequestQuery,
    variables: { request_id: request_id, recaptcha_code: recaptcha_code, ...extraParams },
  });
};

export const readRootKey = (account_id, username, backup = false) => {
  return sendQueryRequest({
    query: readRootKeyQuery,
    variables: { account_id: account_id, username: username, backup: backup },
  });
};

export const createRootKey = (
  account_id,
  username,
  root_key,
  backup_root_key
) => {
  return sendMutationRequest({
    mutation: createRootKeyMut,
    variables: {
      account_id: account_id,
      username: username,
      root_key: root_key,
      back_root_key: backup_root_key,
    },
  });
};

export const updateRootKey = (
  account_id,
  username,
  root_key,
  backup_root_key
) => {
  return sendMutationRequest({
    mutation: updateRootKeyMut,
    variables: {
      account_id: account_id,
      username: username,
      root_key: root_key,
      back_root_key: backup_root_key,
    },
  });
};

export const submitRequestData = (input) => {
  return sendMutationRequest({
    mutation: submitRequestDataMut,
    variables: input,
  });
};

export const readRequestedDataRequest = (uuid, account_id, username, email) => {
  return sendQueryRequest({
    query: readRequestedDataQuery,
    variables: {
      uuid: uuid,
      account_id: account_id,
      username: username,
      req_email: email,
    },
    fetchPolicy: "network-only",
  });
};

export const readRequestHistoryRequest = (
  account_id,
  username,
  start_date = "",
  end_date = ""
) => {
  return sendQueryRequest({
    query: requestHistoryQuery,
    variables: {
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
    },
    fetchPolicy: "network-only",
  });
};

export const readRequestHistoryByLastPostRequest = (
  account_id,
  username,
  last_evaluated_request,
  start_date = "",
  end_date = ""
) => {
  return sendQueryRequest({
    query: requestHistoryByLastEvalutedPostQuery,
    variables: {
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
      last_evaluated_request: last_evaluated_request,
    },
    fetchPolicy: "network-only",
  });
};

export const readNotifications = (account_id, service_name, username) => {
  return sendQueryRequest({
    query: notificationsQuery,
    variables: {
      account_id: account_id,
      service_name: service_name,
      username: username,
    },
    fetchPolicy: "network-only",
  });
};

export const readNotificationsByLastNotification = (
  account_id,
  service_name,
  username,
  last_evaluted_notification
) => {
  return sendQueryRequest({
    query: notificationsQueryByLastNotificationQuery,
    variables: {
      account_id: account_id,
      service_name: service_name,
      username: username,
      last_evaluted_notification: last_evaluted_notification,
    },
    fetchPolicy: "network-only",
  });
};

export const createTemplate = (
  account_id,
  username,
  name,
  description,
  template
) => {
  return sendMutationRequest({
    mutation: createTemplateMut,
    variables: {
      account_id: account_id,
      username: username,
      name: name,
      description: description,
      template: template,
    },
    fetchPolicy: "network-only",
  });
};

export const updateTemplate = (
  account_id,
  username,
  name,
  description,
  template
) => {
  return sendMutationRequest({
    mutation: updateTemplateMut,
    variables: {
      account_id: account_id,
      username: username,
      name: name,
      description: description,
      template: template,
    },
    fetchPolicy: "network-only",
  });
};

export const deleteTemplate = (account_id, username, name) => {
  return sendMutationRequest({
    mutation: deleteTemplateMut,
    variables: { account_id: account_id, username: username, name: name },
    fetchPolicy: "network-only",
  });
};

export const getTemplate = (account_id, username, name) => {
  return sendQueryRequest({
    query: getTemplateQuery,
    variables: { account_id: account_id, username: username, name: name },
    fetchPolicy: "network-only",
  });
};

export const getTemplates = (account_id, username, name = "") => {
  return sendQueryRequest({
    query: getTemplatesQuery,
    variables: { account_id: account_id, username: username, name: name },
    fetchPolicy: "network-only",
  });
};

export const getTemplatesByLastEvaluatedTemplate = (
  account_id,
  username,
  last_evaluated_template,
  name = ""
) => {
  return sendQueryRequest({
    query: getTemplatesQuery,
    variables: {
      account_id: account_id,
      username: username,
      last_evaluated_template: last_evaluated_template,
      name: name,
    },
    fetchPolicy: "network-only",
  });
};

export const createResponse = (session, files, extraParams = {}) => {
  return sendMutationRequest({
    mutation: createResponseMut,
    variables: { session: session, files: files, ...extraParams },
    fetchPolicy: "network-only",
  });
};

export const createResponseWithLimit = (session, files, access_limit, start_date, end_date, extraParams = {}) => {
  console.log({session, files, access_limit, start_date, end_date, extraParams})
  return sendMutationRequest({
    mutation: createResponseWithLimitMut,
    variables: {
      session: session,
      files: files,
      access_limit: access_limit,
      start_date: start_date,
      end_date: end_date,
      ...extraParams,
    },
    fetchPolicy: "network-only",
  });
};

export const getResponse = (
  account_id,
  username,
  requester_id,
  respondent_id
) => {
  return sendQueryRequest({
    query: getResponseQuery,
    variables: {
      account_id: account_id,
      username: username,
      requester_id: requester_id,
      respondent_id: respondent_id,
    },
    fetchPolicy: "network-only",
  });
};

export const getResponses = (account_id, username, start_date, end_date) => {
  return sendQueryRequest({
    query: listResponsesQuery,
    variables: {
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
    },
    fetchPolicy: "network-only",
  });
};

export const getResponsesWithLastEvaluatedResponse = (
  account_id,
  username,
  start_date,
  end_date,
  last_evaluated_response
) => {
  return sendQueryRequest({
    query: listResponsesWithLastEvaluatedResponseQuery,
    variables: {
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
      last_evaluated_response: last_evaluated_response,
    },
    fetchPolicy: "network-only",
  });
};

export const getSendAccessHistoryByUsername = (
  account_id,
  username,
  start_date,
  end_date
) => {
  return sendQueryRequest({
    query: getSendAccessHistoryByUsernameQuery,
    variables: {
      username: username,
      account_id: account_id,
      start_date: start_date,
      end_date: end_date,
    },
    fetchPolicy: "network-only",
  });
};

export const getSendAccessHistoryByUsernameByLastEvaluatedAccessHistory = (
  account_id,
  username,
  start_date,
  end_date,
  last_evaluated_access_history
) => {
  return sendQueryRequest({
    query: getSendAccessHistoryByUsernameByLastEvaluatedAccessHistoryQuery,
    variables: {
      username: username,
      account_id: account_id,
      start_date: start_date,
      end_date: end_date,
      last_evaluated_access_history: last_evaluated_access_history,
    },
    fetchPolicy: "network-only",
  });
};

export const getRequest = (account_id, username, request_id, respondent_id) => {
  return sendQueryRequest({
    query: getRequestQuery,
    variables: {
      account_id: account_id,
      username: username,
      request_id: request_id,
      respondent_id: respondent_id,
    },
    fetchPolicy: "network-only",
  });
};

export const updateRequest = (input) => {
  return sendMutationRequest({
    mutation: updateRequestMut,
    variables: input,
    fetchPolicy: "network-only",
  });
};

export const createDropzone = (input) => {
  return sendMutationRequest({
    mutation: createDropzoneMut,
    variables: input,
    fetchPolicy: "network-only",
  });
};

export const updateDropzone = (input) => {
  return sendMutationRequest({
    mutation: updateDropzoneMut,
    variables: input,
    fetchPolicy: "network-only",
  });
};

export const accessDropzone = (
  id,
  password,
  dropzone_id,
  respondent_id,
  recaptcha_code,
  extraParams = {}
) => {
  if (password) {
    return sendMutationRequest({
      mutation: accessDropzoneMut,
      variables: {
        dropzone_key: id,
        dropzone_id: dropzone_id,
        password: password,
        respondent_id: respondent_id,
        recaptcha_code: recaptcha_code,
        ...extraParams
      },
      fetchPolicy: "network-only",
    });
  } else {
    return sendMutationRequest({
      mutation: accessDropzoneWithoutPasswordMut,
      variables: {
        dropzone_key: id,
        dropzone_id: dropzone_id,
        respondent_id: respondent_id,
        recaptcha_code: recaptcha_code,
        ...extraParams
      },
      fetchPolicy: "network-only",
    });
  }
};

export const getDropzone = (account_id, username, dropzone_id) => {
  return sendQueryRequest({
    query: getDropzoneQuery,
    variables: {
      account_id: account_id,
      username: username,
      dropzone_id: dropzone_id,
    },
    fetchPolicy: "network-only",
  });
};

export const listDropzones = (account_id, username, start_date, end_date) => {
  return sendQueryRequest({
    query: listDropzonesQuery,
    variables: {
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
    },
    fetchPolicy: "network-only",
  });
};

export const listDropzonesByLastEvaluatedDropzone = (
  account_id,
  username,
  start_date,
  end_date,
  last_evaluated_dropzone
) => {
  return sendQueryRequest({
    query: listDropzonesByLastEvaluatedDropzoneQuery,
    variables: {
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
      last_evaluated_dropzone: last_evaluated_dropzone,
    },
  });
};

export const getDropzoneResponsesWithDropzoneKey = (
  dropzone_name,
  account_id,
  username,
  start_date,
  end_date
) => {
  return sendQueryRequest({
    query: dropzoneResponsesWithDropzoneKeyQuery,
    variables: {
      dropzone_name: dropzone_name,
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
    },
    fetchPolicy: "network-only",
  });
};

export const getDropzoneResponsesWithDropzoneKeyByLastEvaluatedResponse = (
  dropzone_name,
  account_id,
  username,
  start_date,
  end_date,
  last_evaluated_response
) => {
  return sendQueryRequest({
    query: dropzoneResponsesWithDropzoneKeyByLastEvaluatedRepsonseQuery,
    variables: {
      dropzone_name: dropzone_name,
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
      last_evaluated_response: last_evaluated_response,
    },
    fetchPolicy: "network-only",
  });
};

export const getDropzoneResponses = (
  account_id,
  username,
  start_date,
  end_date
) => {
  return sendQueryRequest({
    query: dropzoneResponsesQuery,
    variables: {
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
    },
    fetchPolicy: "network-only",
  });
};

export const getDropzoneResponsesByLastEvaluatedResponse = (
  account_id,
  username,
  start_date,
  end_date,
  last_evaluated_response
) => {
  return sendQueryRequest({
    query: dropzoneResponsesByLastEvaluatedRepsonseQuery,
    variables: {
      account_id: account_id,
      username: username,
      start_date: start_date,
      end_date: end_date,
      last_evaluated_response: last_evaluated_response,
    },
    fetchPolicy: "network-only",
  });
};

export const getResponsesByRespondent = (
  account_id,
  username,
  respondent_id
) => {
  return sendQueryRequest({
    query: listResponsesByRespondentQuery,
    variables: {
      account_id: account_id,
      username: username,
      respondent_id: respondent_id,
    },
    fetchPolicy: "network-only",
  });
};

export const getResponsesByRespondentWithLastEvaluatedResponse = (
  account_id,
  username,
  respondent_id,
  last_evaluated_response
) => {
  return sendQueryRequest({
    query: listResponsesByRespondentByLastEvaluatedResponseQuery,
    variables: {
      account_id: account_id,
      username: username,
      respondent_id: respondent_id,
      last_evaluated_response: last_evaluated_response,
    },
    fetchPolicy: "network-only",
  });
};

export const updateSend = (variables) => {
  return sendMutationRequest({
    mutation: updateSendMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
};

export const expireSend = (variables) =>
  sendMutationRequest({ mutation: expireSendMut, variables: variables });
