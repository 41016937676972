import { gql } from "@apollo/client";
export const getVendorQuery = gql`
  query MyQuery($username: String = "", $account_id: String = "") {
    registrar_v1_GetVendor(
      input: { account_id: $account_id, username: $username }
    ) {
      logo_url
      vendor {
        created_at
        description
        name
        plugin_count
        rating
        registered_by
        status
        updated_at
        vendor_id
      }
    }
  }
`;

export const registerVendorMut = gql`
  mutation MyMutation(
    $username: String = ""
    $name: String = ""
    $logo_size: BigInt = 0
    $description: String = ""
    $account_id: String = ""
  ) {
    registrar_v1_RegisterVendor(
      input: {
        logo_size: $logo_size
        description: $description
        account_id: $account_id
        name: $name
        username: $username
      }
    ) {
      logo_url
      vendor {
        created_at
        name
        description
        plugin_count
        rating
        registered_by
        status
        updated_at
        vendor_id
      }
    }
  }
`;

export const listPluginsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $vendor_id: String = ""
    $name: String = ""
  ) {
    registrar_v1_ListPlugins(
      input: { account_id: $account_id, vendor_id: $vendor_id, name: $name }
    ) {
      last_evaluated_plugin {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      plugins {
        account_id
        category
        created_at
        deploy_count
        description
        git_url
        logo
        name
        option_schema
        option_schema_ui_schema
        plugin_id
        rating
        readme_url
        registered_by
        run_count
        status
        sub_category
        tags
        updated_at
        vendor_id
        vendor_name
        version
        visibility
      }
    }
  }
`;

export const listPluginsByLastEvaulatedQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $vendor_id: String = ""
    $name: String = ""
    $last_evaluated_plugin: api_registrar_v1_LastEvaluated_Input = {}
  ) {
    registrar_v1_ListPlugins(
      input: {
        account_id: $account_id
        vendor_id: $vendor_id
        name: $name
        last_evaluated_plugin: $last_evaluated_plugin
      }
    ) {
      last_evaluated_plugin {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      plugins {
        account_id
        category
        created_at
        deploy_count
        description
        git_url
        logo
        name
        option_schema
        option_schema_ui_schema
        plugin_id
        rating
        readme_url
        registered_by
        run_count
        status
        sub_category
        tags
        updated_at
        vendor_id
        vendor_name
        version
        visibility
      }
    }
  }
`;
export const registerPluginMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $category: api_registrar_v1_PluginCategory = PLUGIN_CATEGORY_UNKNOWN
    $description: String = ""
    $git_url: String = ""
    $logo_size: BigInt = 0
    $name: String = ""
    $option_schema: String = ""
    $option_schema_ui_schema: String = ""
    $sub_category: api_registrar_v1_PluginSubCategory = PLUGIN_SUBCATEGORY_UNKNOWN
    $tags: [String] = ""
    $username: String = ""
    $vendor_id: String = ""
    $visibility: api_registrar_v1_Visibility = VISIBILITY_PRIVATE
  ) {
    registrar_v1_RegisterPlugin(
      input: {
        visibility: $visibility
        username: $username
        vendor_id: $vendor_id
        tags: $tags
        sub_category: $sub_category
        option_schema_ui_schema: $option_schema_ui_schema
        name: $name
        logo_size: $logo_size
        description: $description
        category: $category
        account_id: $account_id
        git_url: $git_url
        option_schema: $option_schema
      }
    ) {
      plugin {
        account_id
        created_at
        deploy_count
        category
        description
        git_url
        logo
        name
        option_schema_ui_schema
        option_schema
        plugin_id
        rating
        readme_url
        registered_by
        run_count
        service_provider
        service_provider_logo
        status
        sub_category
        tags
        updated_at
        vendor_id
        vendor_name
        version
        visibility
      }
      signed_url
    }
  }
`;

export const configurePluginMut = gql`
  mutation MyMutation(
    $vendor_id: String = ""
    $username: String = ""
    $plugin_id: String = ""
    $label: String = ""
    $json_option_data: String = ""
    $account_id: String = ""
  ) {
    registrar_v1_ConfigurePlugin(
      input: {
        account_id: $account_id
        json_option_data: $json_option_data
        label: $label
        plugin_id: $plugin_id
        username: $username
        vendor_id: $vendor_id
      }
    ) {
      configured_plugin {
        account_id
        configure_id
        configured_by
        created_at
        json_option_data
        label
        plugin_id
        plugin_name
        readme_url
        status
        updated_at
        username
        vendor_id
        vendor_name
        version
      }
    }
  }
`;

export const updateConfiguredPluginMut = gql`
  mutation MyMutation(
    $vendor_id: String = ""
    $username: String = ""
    $plugin_id: String = ""
    $label: String = ""
    $json_option_data: String = ""
    $configure_id: String = ""
    $account_id: String = ""
  ) {
    registrar_v1_UpdateConfigurePlugin(
      input: {
        account_id: $account_id
        configure_id: $configure_id
        json_option_data: $json_option_data
        label: $label
        plugin_id: $plugin_id
        username: $username
        vendor_id: $vendor_id
      }
    )
  }
`;

export const getConfiguredPluginsQuery = gql`
  query MyQuery(
    $vendor_id: String = ""
    $username: String = ""
    $plugin_id: String = ""
    $account_id: String = ""
  ) {
    registrar_v1_ListConfiguredPlugins(
      input: {
        account_id: $account_id
        plugin_id: $plugin_id
        username: $username
        vendor_id: $vendor_id
      }
    ) {
      configured_plugins {
        account_id
        configure_id
        configured_by
        created_at
        json_option_data
        label
        plugin_id
        plugin_name
        readme_url
        updated_at
        status
        username
        vendor_id
        vendor_name
        version
      }
      last_evaluated {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
    }
  }
`;

export const listPublishedPluginsQuery = gql`
  query MyQuery($vendor_id: String = "", $account_id: String = "") {
    registrar_v1_ListPublishedPlugins(
      input: { account_id: $account_id, vendor_id: $vendor_id }
    ) {
      last_evaluated_plugin {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      plugins {
        account_id
        category
        created_at
        deploy_count
        description
        git_url
        logo
        name
        option_schema
        option_schema_ui_schema
        plugin_id
        rating
        readme_url
        registered_by
        run_count
        service_provider
        service_provider_logo
        status
        sub_category
        tags
        updated_at
        vendor_id
        version
        vendor_name
        visibility
      }
      signed_urls
    }
  }
`;

export const listPublishedPluginsByLasEvaluatedPluginQuery = gql`
  query MyQuery(
    $vendor_id: String = ""
    $account_id: String = ""
    $last_evaluated_plugin: api_registrar_v1_LastEvaluated_Input = {}
  ) {
    registrar_v1_ListPublishedPlugins(
      input: {
        account_id: $account_id
        vendor_id: $vendor_id
        last_evaluated_plugin: $last_evaluated_plugin
      }
    ) {
      last_evaluated_plugin {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      plugins {
        account_id
        category
        created_at
        deploy_count
        description
        git_url
        logo
        name
        option_schema
        option_schema_ui_schema
        plugin_id
        rating
        readme_url
        registered_by
        run_count
        service_provider
        service_provider_logo
        status
        sub_category
        tags
        updated_at
        vendor_id
        version
        vendor_name
        visibility
      }
      signed_urls
    }
  }
`;

export const listAllPluginsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $status: api_registrar_v1_PluginStatus = PLUGIN_STATUS_PENDING_APPROVAL
    $username: String = ""
  ) {
    registrar_v1_ListAllPlugins(
      input: { account_id: $account_id, status: $status, username: $username }
    ) {
      plugins {
        visibility
        version
        vendor_name
        vendor_id
        updated_at
        tags
        sub_category
        status
        service_provider_logo
        service_provider
        run_count
        registered_by
        readme_url
        rating
        plugin_id
        option_schema_ui_schema
        option_schema
        name
        logo
        git_url
        description
        deploy_count
        created_at
        account_id
        category
      }
      signed_urls
      last_evaluated_plugin {
        Gsi1Pk
        Pk
        Gsi1Sk
        Sk
      }
    }
  }
`;

export const listAllPluginsByLasEvaluatedPluginQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $category: api_registrar_v1_PluginCategory = PLUGIN_CATEGORY_ASSET_DISCOVERY
    $status: api_registrar_v1_PluginStatus = PLUGIN_STATUS_PENDING_APPROVAL
    $sub_category: api_registrar_v1_PluginSubCategory = PLUGIN_SUBCATEGORY_IDENTITY_PROVIDER
    $username: String = ""
    $last_evaluated_plugin: api_registrar_v1_LastEvaluated_Input = {}
  ) {
    registrar_v1_ListAllPlugins(
      input: {
        account_id: $account_id
        category: $category
        status: $status
        sub_category: $sub_category
        username: $username
        last_evaluated_plugin: $last_evaluated_plugin
      }
    ) {
      plugins {
        visibility
        version
        vendor_name
        vendor_id
        updated_at
        tags
        sub_category
        status
        service_provider_logo
        service_provider
        run_count
        registered_by
        readme_url
        rating
        plugin_id
        option_schema_ui_schema
        option_schema
        name
        logo
        git_url
        description
        deploy_count
        created_at
        account_id
        category
      }
      signed_urls
      last_evaluated_plugin {
        Gsi1Pk
        Pk
        Gsi1Sk
        Sk
      }
    }
  }
`;

export const listConfiguredPluginsQuery = gql`
  query MyQuery(
    $vendor_id: String = ""
    $username: String = ""
    $plugin_id: String = ""
    $account_id: String = ""
  ) {
    registrar_v1_ListConfiguredPlugins(
      input: {
        plugin_id: $plugin_id
        username: $username
        vendor_id: $vendor_id
        account_id: $account_id
      }
    ) {
      configured_plugins {
        account_id
        configure_id
        configured_by
        created_at
        json_option_data
        label
        plugin_id
        plugin_name
        readme_url
        status
        status_message
        updated_at
        username
        vendor_id
        vendor_name
        version
      }
      last_evaluated {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
    }
  }
`;

export const listConfiguredPluginsByLasEvaluatedPluginQuery = gql`
  query MyQuery(
    $vendor_id: String = ""
    $username: String = ""
    $plugin_id: String = ""
    $account_id: String = ""
    $last_evaluated: api_registrar_v1_LastEvaluated_Input = {}
  ) {
    registrar_v1_ListConfiguredPlugins(
      input: {
        plugin_id: $plugin_id
        username: $username
        vendor_id: $vendor_id
        account_id: $account_id
        last_evaluated: $last_evaluated
      }
    ) {
      configured_plugins {
        account_id
        configure_id
        configured_by
        created_at
        json_option_data
        label
        plugin_id
        plugin_name
        readme_url
        status
        status_message
        updated_at
        username
        vendor_id
        vendor_name
        version
      }
      last_evaluated {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
    }
  }
`;

export const getPluginQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $plugin_id: String = ""
    $username: String = ""
    $vendor_id: String = ""
  ) {
    registrar_v1_GetPlugin(
      input: {
        account_id: $account_id
        plugin_id: $plugin_id
        username: $username
        vendor_id: $vendor_id
      }
    ) {
      plugin {
        account_id
        category
        created_at
        deploy_count
        description
        git_url
        logo
        option_schema
        name
        option_schema_ui_schema
        plugin_id
        rating
        registered_by
        readme_url
        run_count
        service_provider
        service_provider_logo
        status
        sub_category
        updated_at
        tags
        vendor_id
        vendor_name
        version
      }
      signed_url
    }
  }
`;

export const getConfiguredPluginQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $configure_id: String = ""
    $plugin_id: String = ""
    $username: String = ""
    $vendor_id: String = ""
  ) {
    registrar_v1_GetConfiguredPlugin(
      input: {
        account_id: $account_id
        configure_id: $configure_id
        plugin_id: $plugin_id
        username: $username
        vendor_id: $vendor_id
      }
    ) {
      configured_plugin {
        account_id
        configure_id
        configured_by
        created_at
        json_option_data
        label
        plugin_id
        plugin_name
        readme_url
        status
        updated_at
        username
        vendor_id
        vendor_name
      }
    }
  }
`;

export const updatePluginMut = gql`
  mutation MyMutation2(
    $account_id: String = ""
    $description: String = ""
    $logo_size: BigInt = 0
    $option_schema: String = ""
    $option_schema_ui_schema: String = ""
    $plugin_id: String = ""
    $vendor_id: String = ""
  ) {
    registrar_v1_UpdatePlugin(
      input: {
        account_id: $account_id
        description: $description
        logo_size: $logo_size
        option_schema: $option_schema
        option_schema_ui_schema: $option_schema_ui_schema
        plugin_id: $plugin_id
        vendor_id: $vendor_id
      }
    ) {
      signed_url
      plugin {
        account_id
        category
        created_at
        deploy_count
        description
        git_url
        logo
        option_schema
        plugin_id
        option_schema_ui_schema
        name
        rating
        readme_url
        run_count
        registered_by
        service_provider
        service_provider_logo
        status
        sub_category
        tags
        updated_at
        vendor_id
        vendor_name
        version
      }
    }
  }
`;

export const listVendorsQuery = gql`
  query MyQuery(
    $username: String = ""
    $account_id: String = ""
    $status: api_registrar_v1_VendorStatus = VENDOR_STATUS_PENDING_APPROVAL_UNSPECIFIED
  ) {
    registrar_v1_ListVendors(
      input: { account_id: $account_id, username: $username, status: $status }
    ) {
      last_evaluated_vendor {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      logo_urls
      vendors {
        created_at
        description
        name
        plugin_count
        rating
        registered_by
        status
        updated_at
        vendor_id
      }
    }
  }
`;

export const listVendorsQueryByLastEvaluatedVendor = gql`
  query MyQuery(
    $username: String = ""
    $name: String = ""
    $account_id: String = ""
    $last_evaluated_vendor: api_registrar_v1_LastEvaluated_Input = {}
    $status: api_registrar_v1_VendorStatus = VENDOR_STATUS_PENDING_APPROVAL_UNSPECIFIED
  ) {
    registrar_v1_ListVendors(
      input: {
        account_id: $account_id
        name: $name
        username: $username
        last_evaluated_vendor: $last_evaluated_vendor
        status: $status
      }
    ) {
      last_evaluated_vendor {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      vendors {
        created_at
        description
        name
        plugin_count
        rating
        registered_by
        status
        updated_at
        vendor_id
      }
    }
  }
`;

export const startPluginRunMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $configure_id: String = ""
    $plugin_id: String = ""
    $username: String = ""
    $vendor_id: String = ""
  ) {
    registrar_v1_StartPluginRun(
      input: {
        vendor_id: $vendor_id
        username: $username
        plugin_id: $plugin_id
        configure_id: $configure_id
        account_id: $account_id
      }
    )
  }
`;

export const listPluginRunsQuery = gql`
  query MyQuery(
    $username: String = ""
    $configure_id: String = ""
    $account_id: String = ""
  ) {
    registrar_v1_ListPluginRuns(
      input: {
        account_id: $account_id
        username: $username
        configure_id: $configure_id
      }
    ) {
      plugin_runs {
        account_id
        created_at
        log_stream
        message
        plugin_id
        run_id
        plugin_name
        status
        updated_at
        username
        vendor_id
        vendor_name
      }
      last_evaluated {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
    }
  }
`;

export const listPluginRunsByLastEvaluatedQuery = gql`
  query MyQuery(
    $username: String = ""
    $configure_id: String = ""
    $account_id: String = ""
    $last_evaluated: api_registrar_v1_LastEvaluated_Input = {}
  ) {
    registrar_v1_ListPluginRuns(
      input: {
        username: $username
        configure_id: $configure_id
        account_id: $account_id
        last_evaluated: $last_evaluated
      }
    ) {
      plugin_runs {
        account_id
        created_at
        log_stream
        message
        plugin_id
        run_id
        plugin_name
        status
        updated_at
        username
        vendor_id
        vendor_name
      }
      last_evaluated {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
    }
  }
`;

export const changePluginStatusMut = gql`
  mutation MyMutation(
    $vendor_id: String = ""
    $status: api_registrar_v1_PluginStatus = PLUGIN_STATUS_APPROVED
    $plugin_id: String = ""
    $account_id: String = ""
    $username: String = ""
  ) {
    registrar_v1_ChangePluginStatus(
      input: {
        account_id: $account_id
        plugin_id: $plugin_id
        status: $status
        vendor_id: $vendor_id
        username: $username
      }
    )
  }
`;

export const changeVendorStatusMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $username: String = ""
    $vendor_id: String = ""
    $status: api_registrar_v1_VendorStatus = VENDOR_STATUS_PENDING_APPROVAL_UNSPECIFIED
  ) {
    registrar_v1_ChangeVendorStatus(
      input: {
        account_id: $account_id
        status: $status
        username: $username
        vendor_id: $vendor_id
      }
    )
  }
`;

export const listAllAndConfiguredPluginsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $vendor_id: String = ""
  ) {
    registrar_v1_ListPublishedPlugins(
      input: { account_id: $account_id, vendor_id: $vendor_id }
    ) {
      last_evaluated_plugin {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      plugins {
        account_id
        category
        created_at
        deploy_count
        description
        git_url
        logo
        name
        option_schema
        option_schema_ui_schema
        plugin_id
        rating
        readme_url
        registered_by
        run_count
        service_provider
        service_provider_logo
        status
        sub_category
        tags
        updated_at
        vendor_id
        version
        vendor_name
      }
      signed_urls
    }
    registrar_v1_ListConfiguredPlugins(
      input: {
        account_id: $account_id
        username: $username
        vendor_id: $vendor_id
      }
    ) {
      configured_plugins {
        configure_id
        account_id
        configured_by
        created_at
        json_option_data
        label
        plugin_id
        plugin_name
        readme_url
        status
        updated_at
        username
        vendor_id
        vendor_name
        version
      }
      last_evaluated {
        Gsi1Pk
        Gsi1Sk
        Sk
        Pk
      }
    }
  }
`;

export const publishPluginMut = gql`
  mutation MyMutation2(
    $vendor_id: String = ""
    $username: String = ""
    $plugin_id: String = ""
    $change_type: api_registrar_v1_PluginChangeType = PLUGIN_CHANGE_TYPE_UNKNOWN
    $account_id: String = ""
  ) {
    registrar_v1_PublishPlugin(
      input: {
        account_id: $account_id
        change_type: $change_type
        plugin_id: $plugin_id
        vendor_id: $vendor_id
        username: $username
      }
    )
  }
`;

export const deletePluginInstanceMut = gql`
  mutation MyMutation(
    $vendor_id: String = ""
    $username: String = ""
    $plugin_id: String = ""
    $configure_id: String = ""
    $account_id: String = ""
  ) {
    registrar_v1_DeleteConfiguredPlugin(
      input: {
        plugin_id: $plugin_id
        configure_id: $configure_id
        account_id: $account_id
        username: $username
        vendor_id: $vendor_id
      }
    )
  }
`;

export const listPluginRunLogsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $log_stream: String = ""
    $plugin_name: String = ""
    $username: String = ""
    $vendor_name: String = ""
  ) {
    registrar_v1_GetPluginRunLogs(
      input: {
        account_id: $account_id
        log_stream: $log_stream
        plugin_name: $plugin_name
        username: $username
        vendor_name: $vendor_name
      }
    ) {
      logs
    }
  }
`;
