import { gql } from "@apollo/client";

export const createAssetMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $username: String = ""
    $tags: [String] = ""
    $props: String = ""
    $name: String = ""
    $category_id: String = ""
  ) {
    asset_intelligence_v1_CreateAsset(
      input: {
        account_id: $account_id
        username: $username
        tags: $tags
        props: $props
        name: $name
        category_id: $category_id
      }
    ) {
      asset {
        id
        props
        tags
      }
    }
  }
`;

export const updateAssetMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $add_tags: [String] = ""
    $remove_tags: [String] = ""
    $username: String = ""
    $asset_id: String = ""
    $update_props: String = ""
  ) {
    asset_intelligence_v1_UpdateAssets(
      input: {
        account_id: $account_id
        add_tags: $add_tags
        remove_tags: $remove_tags
        username: $username
        asset_id: $asset_id
        update_props: $update_props
      }
    )
  }
`;

export const getAssetQuery = gql`
  query GetAsset(
    $account_id: String = ""
    $username: String = ""
    $asset_id: String = ""
    $include_relations: Boolean = false
  ) {
    asset_intelligence_v1_GetAsset(
      input: {
        account_id: $account_id
        username: $username
        asset_id: $asset_id
        include_relations: $include_relations
      }
    ) {
      assets {
        id
        props
        tags
      }
      end_assets {
        id
        props
        tags
      }
      relations {
        end_asset_id
        props
        start_asset_id
        type
      }
    }
  }
`;

export const deleteAssetsMut = gql`
  mutation DeleteAsset(
    $account_id: String = ""
    $username: String = ""
    $asset_id: String = ""
    $where: api_asset_intelligence_v1_WhereClause_Input = {}
    $tags: [String] = ""
  ) {
    asset_intelligence_v1_DeleteAssets(
      input: {
        account_id: $account_id
        asset_id: $asset_id
        username: $username
        where: $where
        tags: $tags
      }
    )
  }
`;

export const listAssetsAndRelationsQuery = gql`
  query MyQuery($username: String = "", $tags: [String] = "", $source: String = "", $resource_category: String = "", $page_size: Int = 10, $page_number: Int = 1, $account_id: String = "") {
  asset_intelligence_v1_ListAssets(
    input: {account_id: $account_id, page_number: $page_number, page_size: $page_size, tags: $tags, source: $source, username: $username, resource_category: $resource_category}
  ) {
    assets {
      props
      tags
      id
    }
  }
}
`;


export const listAssetWithSearchQuery = gql`
query MyQuery($username: String = "", $search_term: String = "", $page_number: Int = 1, $page_size: Int = 10, $account_id: String = "") {
  asset_intelligence_v1_SearchAssets(
    input: {account_id: $account_id, search_term: $search_term, page_size: $page_size, page_number: $page_number, username: $username}
  ) {
    assets {
      id
      props
      tags
    }
  }
}
`

export const exploreAssetsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $page_size: Int = 50
    $page_number: Int = 1
    $start_asset_id: String = ""
    $start_asset_tags: [String] = ""
  ) {
    asset_intelligence_v1_ListRelations(
      input: {
        account_id: $account_id
        page_number: $page_number
        page_size: $page_size
        username: $username
        start_asset_id: $start_asset_id
        start_asset_tags: $start_asset_tags
      }
    ) {
      assets {
        id
        props
        tags
      }
      relations {
        end_asset_id
        props
        start_asset_id
        type
      }
    }
  }
`;

export const getRelationQuery = gql`
  query MyQuery(
    $username: String = ""
    $id: String = ""
    $account_id: String = ""
  ) {
    assetinventory_v1_GetRelation(
      input: { account_id: $account_id, id: $id, username: $username }
    ) {
      relation {
        end_id
        id
        props
        start_id
        type
      }
    }
  }
`;

export const createRelationMut = gql`
 mutation MyMutation($account_id: String = "", $end_asset_ids: [String] = "", $start_asset_ids: [String] = "", $type: String = "", $username: String = "", $props: String = "") {
  asset_intelligence_v1_CreateRelation(
    input: {account_id: $account_id, end_asset_ids: $end_asset_ids, start_asset_ids: $start_asset_ids, type: $type, props: $props, username: $username}
  ) {
    created_relations_count
  }
}
`;

export const createRelationWithTagsMut = gql`
 mutation MyMutation($account_id: String = "", $start_asset_ids: [String] = "", $type: String = "", $username: String = "", $props: String = "", $end_asset_tags: [String] = "") {
  asset_intelligence_v1_CreateRelation(
    input: {account_id: $account_id, start_asset_ids: $start_asset_ids, type: $type, props: $props, username: $username, end_asset_tags: $end_asset_tags}
  ) {
    created_relations_count
  }
}
`;


export const updateRelationMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $id: String = ""
    $props: JSON = ""
    $type: String = ""
    $username: String = ""
  ) {
    assetinventory_v1_UpdateRelation(
      input: {
        account_id: $account_id
        id: $id
        type: $type
        props: $props
        username: $username
      }
    )
  }
`;

export const deleteRelationMut = gql`
  mutation MyMutation2(
    $account_id: String = ""
    $username: String = ""
    $start_asset_id: String = ""
    $end_asset_id: String = ""
    $types: [String] = ""
  ) {
    asset_intelligence_v1_DeleteRelation(
      input: {
        account_id: $account_id
        username: $username
        start_asset_id: $start_asset_id
        end_asset_id: $end_asset_id
        types: $types
      }
    )
  }
`;

export const askMut = gql`
  mutation MyMutation2(
    $account_id: String = ""
    $question: String = ""
    $username: String = ""
  ) {
    assetinventory_v1_Ask(
      input: {
        account_id: $account_id
        username: $username
        question: $question
      }
    ) {
      answer
    }
  }
`;

export const askAssetQuestionMut = gql`
  query MyQuery(
    $username: String = ""
    $question: String = ""
    $props: JSON = ""
    $labels: [String] = ""
    $account_id: String = ""
  ) {
    assetinventory_v1_ListAssets(
      input: {
        account_id: $account_id
        props: $props
        question: $question
        username: $username
        labels: $labels
      }
    ) {
      answer
    }
  }
`;

export const listAssetsQuery = gql`
  query MyQuery($username: String = "", $tags: [String] = "", $source: String = "", $resource_category: String = "", $page_size: Int = 10, $page_number: Int = 1, $account_id: String = "") {
  asset_intelligence_v1_ListAssets(
    input: {account_id: $account_id, page_number: $page_number, page_size: $page_size, tags: $tags, source: $source, username: $username, resource_category: $resource_category}
  ) {
    assets {
      props
      tags
      id
    }
  }
}
`;

export const createLifecycleMut = gql`
  mutation MyMutation(
    $username: String = ""
    $name: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_CreateLifecycle(
      input: {
        account_id: $account_id
        description: $description
        name: $name
        username: $username
      }
    ) {
      FinalStage {
        created_at
        description
        lifecycle_id
        name
        stage_id
        stage_type
        updated_at
        updated_by
      }
      InitialStage {
        created_at
        description
        lifecycle_id
        name
        stage_id
        stage_type
        updated_at
        updated_by
      }
      lifecycle {
        created_at
        created_by
        description
        lifecycle_id
        name
        total_stages
        updated_at
        updated_by
      }
    }
  }
`;

export const deleteLifecycleMut = gql`
  mutation MyMutation(
    $username: String = ""
    $lifecycle_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_DeleteLifecycle(
      input: {
        account_id: $account_id
        lifecycle_id: $lifecycle_id
        username: $username
      }
    )
  }
`;

export const updateLifecycleMut = gql`
  mutation MyMutation(
    $username: String = ""
    $name: String = ""
    $lifecycle_id: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_UpdateLifecycle(
      input: {
        account_id: $account_id
        description: $description
        name: $name
        lifecycle_id: $lifecycle_id
        username: $username
      }
    )
  }
`;

export const getLifecycleQuery = gql`
  query MyQuery(
    $username: String = ""
    $lifecycle_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_GetLifecycle(
      input: {
        account_id: $account_id
        lifecycle_id: $lifecycle_id
        username: $username
      }
    ) {
      lifecycle {
        created_at
        created_by
        description
        lifecycle_id
        name
        total_stages
        updated_at
        updated_by
      }
    }
  }
`;

export const listLifecyclesQuery = gql`
  query MyQuery(
    $username: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $lifecycle_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_ListLifecycles(
      input: {
        account_id: $account_id
        lifecycle_id: $lifecycle_id
        page_number: $page_number
        username: $username
        page_size: $page_size
      }
    ) {
      lifecycles {
        created_at
        created_by
        description
        lifecycle_id
        name
        total_stages
        updated_at
        updated_by
      }
    }
  }
`;

export const createStageWithNextStageMut = gql`
  mutation MyMutation(
    $relations: [api_asset_intelligence_v1_StageRelation_Input] = {}
    $username: String = ""
    $name: String = ""
    $lifecycle_name: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_CreateStage(
      input: {
        account_id: $account_id
        description: $description
        lifecycle_name: $lifecycle_name
        name: $name
        relations: $relations
        username: $username
      }
    ) {
      stage {
        id
        created_at
        description
        lifecycle_name
        name
        updated_at
        updated_by
        relations {
          description
          end_stage_name
          type
        }
      }
    }
  }
`;

export const createStageMut = gql`
  mutation MyMutation(
    $username: String = ""
    $name: String = ""
    $lifecycle_id: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_CreateStage(
      input: {
        description: $description
        lifecycle_id: $lifecycle_id
        name: $name
        username: $username
        account_id: $account_id
      }
    ) {
      stage {
        created_at
        description
        lifecycle_id
        name
        stage_id
        stage_type
        updated_at
        updated_by
      }
    }
  }
`;

export const createStageRelationMut = gql`
  mutation MyMutation(
    $username: String = ""
    $stage_id: String = ""
    $lifecycle_id: String = ""
    $end_stage_id: String = ""
    $account_id: String = ""
    $transition: api_asset_intelligence_v1_StageTransition_Input = {
      effect: STAGE_EFFECT_PUSH_EVENT
      guard_condition: ""
      trigger: STAGE_TRIGGER_ON_UPDATE
    }
  ) {
    asset_intelligence_v1_CreateStageTransition(
      input: {
        username: $username
        stage_id: $stage_id
        lifecycle_id: $lifecycle_id
        end_stage_id: $end_stage_id
        account_id: $account_id
        transition: $transition
      }
    )
  }
`;

export const updateStageMut = gql`
  mutation MyMutation(
    $username: String = ""
    $stage_id: String = ""
    $name: String = ""
    $lifecycle_id: String = ""
    $description: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_UpdateStage(
      input: {
        account_id: $account_id
        description: $description
        lifecycle_id: $lifecycle_id
        name: $name
        username: $username
        stage_id: $stage_id
      }
    )
  }
`;

export const updateStageRelationMut = gql`
  mutation MyMutation(
    $transition: api_asset_intelligence_v1_StageTransition_Input = {
      effect: STAGE_EFFECT_PUSH_EVENT
      guard_condition: ""
      trigger: STAGE_TRIGGER_ON_UPDATE
    }
    $stage_id: String = ""
    $username: String = ""
    $lifecycle_id: String = ""
    $end_stage_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_UpdateStageTransition(
      input: {
        account_id: $account_id
        end_stage_id: $end_stage_id
        lifecycle_id: $lifecycle_id
        stage_id: $stage_id
        username: $username
        transition: $transition
      }
    )
  }
`;

export const deleteStageMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $lifecycle_id: String = ""
    $stage_id: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_DeleteStage(
      input: {
        lifecycle_id: $lifecycle_id
        username: $username
        stage_id: $stage_id
        account_id: $account_id
      }
    )
  }
`;

export const deleteStageRelationMut = gql`
  mutation MyMutation(
    $username: String = ""
    $stage_id: String = ""
    $lifecycle_id: String = ""
    $end_stage_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_DeleteStageTransition(
      input: {
        account_id: $account_id
        end_stage_id: $end_stage_id
        stage_id: $stage_id
        lifecycle_id: $lifecycle_id
        username: $username
      }
    )
  }
`;

export const listStagesQuery = gql`
  query MyQuery(
    $username: String = ""
    $lifecycle_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_ListStages(
      input: {
        account_id: $account_id
        lifecycle_id: $lifecycle_id
        username: $username
      }
    ) {
      stages {
        description
        created_at
        lifecycle_id
        name
        stage_id
        stage_type
        updated_at
        updated_by
      }
      relations {
        end_stage_id
        start_stage_id
        transition {
          effect
          guard_condition
          trigger
        }
      }
    }
  }
`;

export const addAssetToStageMut = gql`
  mutation MyMutation(
    $username: String = ""
    $stage_id: String = ""
    $lifecycle_id: String = ""
    $id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_AddAssetToStage(
      input: {
        account_id: $account_id
        id: $id
        lifecycle_id: $lifecycle_id
        stage_id: $stage_id
        username: $username
      }
    )
  }
`;

export const removeAssetFromStageMut = gql`
  mutation MyMutation(
    $username: String = ""
    $stage_id: String = ""
    $lifecycle_id: String = ""
    $id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_RemoveAssetFromStage(
      input: {
        account_id: $account_id
        id: $id
        stage_id: $stage_id
        lifecycle_id: $lifecycle_id
        username: $username
      }
    )
  }
`;

export const createCategoriesMut = gql`
  mutation MyMutation(
    $username: String = ""
    $ui_schema: String = ""
    $schema: String = ""
    $relations: JSON = ""
    $name: String = ""
    $icon_size: BigInt = 0
    $description: String = ""
    $default_tags: [String] = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_CreateCategory(
      input: {
        relations: $relations
        account_id: $account_id
        default_tags: $default_tags
        description: $description
        icon_size: $icon_size
        name: $name
        ui_schema: $ui_schema
        schema: $schema
        username: $username
      }
    ) {
      category {
        category_id
        created_at
        default_tags
        description
        icon
        name
        relations
        ui_schema
        updated_at
        schema
      }
      signed_url
    }
  }
`;

export const getCategoryQuery = gql`
  query MyQuery(
    $username: String = ""
    $category_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_GetCategory(
      input: {
        account_id: $account_id
        category_id: $category_id
        username: $username
      }
    ) {
      category {
        category_id
        created_at
        default_tags
        description
        icon
        name
        relations
        schema
        ui_schema
        updated_at
      }
    }
  }
`;

export const deleteCategoryMut = gql`
  mutation MyMutation(
    $username: String = ""
    $category_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_DeleteCategory(
      input: {
        account_id: $account_id
        category_id: $category_id
        username: $username
      }
    )
  }
`;

export const updateCategoryMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $category_id: String = ""
    $default_tags: [String] = ""
    $description: String = ""
    $icon_size: BigInt = 0
    $name: String = ""
    $relations: JSON = ""
    $schema: String = ""
    $ui_schema: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_UpdateCategory(
      input: {
        username: $username
        ui_schema: $ui_schema
        schema: $schema
        relations: $relations
        name: $name
        description: $description
        default_tags: $default_tags
        category_id: $category_id
        account_id: $account_id
        icon_size: $icon_size
      }
    ) {
      signed_url
    }
  }
`;

export const listCategoriesQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $ds_managed: Boolean = false
  ) {
    asset_intelligence_v1_ListCategories(
      input: {
        ds_managed: $ds_managed
        account_id: $account_id
        username: $username
      }
    ) {
      categories {
        category_id
        created_at
        default_tags
        description
        ds_managed
        icon
        name
        relations
        schema
        ui_schema
        updated_at
      }
    }
  }
`;

export const updateAssetPropMut = gql`
  mutation MyMutation(
    $asset_id: String = ""
    $account_id: String = ""
    $username: String = ""
    $update_props: String = ""
    $category_id: String = ""
  ) {
    asset_intelligence_v1_UpdateAssets(
      input: {
        asset_id: $asset_id
        account_id: $account_id
        username: $username
        update_props: $update_props
        category_id: $category_id
      }
    )
  }
`;

export const listStageAssetsQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $username: String = ""
    $stage_id: String = ""
    $lifecycle_id: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
  ) {
    asset_intelligence_v1_ListStageAssets(
      input: {
        account_id: $account_id
        username: $username
        stage_id: $stage_id
        lifecycle_id: $lifecycle_id
        page_number: $page_number
        page_size: $page_size
      }
    ) {
      assets {
        props
        id
        tags
      }
    }
  }
`;

export const listAssetStagesQuery = gql`
  query MyQuery(
    $username: String = ""
    $account_id: String = ""
    $page_size: Int = 10
    $page_number: Int = 1
    $asset_id: String = ""
  ) {
    asset_intelligence_v1_ListAssetStages(
      input: {
        username: $username
        account_id: $account_id
        page_number: $page_number
        page_size: $page_size
        asset_id: $asset_id
      }
    ) {
      results {
        total_stages
        state_change_time
        stage_name
        stage_id
        lifecycle_name
        lifecycle_id
      }
    }
  }
`;

export const removeAssetFromStage = gql`
  mutation MyMutation(
    $username: String = ""
    $stage_id: String = ""
    $lifecycle_id: String = ""
    $id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_RemoveAssetFromStage(
      input: {
        account_id: $account_id
        id: $id
        lifecycle_id: $lifecycle_id
        stage_id: $stage_id
        username: $username
      }
    )
  }
`;

export const createNoteMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $asset_id: String = ""
    $discription: String = ""
    $size: BigInt = 0
    $title: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_CreateNote(
      input: {
        account_id: $account_id
        asset_id: $asset_id
        discription: $discription
        size: $size
        title: $title
        username: $username
      }
    ) {
      note {
        asset_id
        created_at
        discription
        file_path
        note_id
        title
        updated_at
      }
      signed_url
    }
  }
`;

export const deleteNoteMut = gql`
  mutation MyMutation(
    $username: String = ""
    $note_id: String = ""
    $asset_id: String = ""
    $account_id: String = ""
  ) {
    asset_intelligence_v1_DeleteNote(
      input: {
        account_id: $account_id
        asset_id: $asset_id
        note_id: $note_id
        username: $username
      }
    )
  }
`;

export const updateNoteMut = gql`
  mutation MyMutation(
    $account_id: String = ""
    $asset_id: String = ""
    $discription: String = ""
    $note_id: String = ""
    $size: BigInt = 0
    $title: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_UpdateNote(
      input: {
        username: $username
        size: $size
        note_id: $note_id
        discription: $discription
        asset_id: $asset_id
        account_id: $account_id
        title: $title
      }
    ) {
      note {
        asset_id
        created_at
        discription
        file_path
        note_id
        title
        updated_at
      }
      signed_url
    }
  }
`;

export const listNoteQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $asset_id: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_ListNotes(
      input: {
        account_id: $account_id
        asset_id: $asset_id
        username: $username
      }
    ) {
      last_evaluated {
        Gsi1Pk
        Gsi1Sk
        Pk
        Sk
      }
      notes {
        asset_id
        created_at
        discription
        file_path
        note_id
        title
        updated_at
      }
    }
  }
`;

export const getNoteQuery = gql`
  query MyQuery(
    $account_id: String = ""
    $asset_id: String = ""
    $note_id: String = ""
    $username: String = ""
  ) {
    asset_intelligence_v1_GetNote(
      input: {
        account_id: $account_id
        note_id: $note_id
        username: $username
        asset_id: $asset_id
      }
    ) {
      note {
        asset_id
        created_at
        discription
        note_id
        file_path
        title
        updated_at
      }
    }
  }
`;

export const getDashboardMetricsQuery = gql`
  query MyQuery($username: String = "", $interval: String = "", $account_id: String = "") {
  asset_intelligence_v1_GetDashboardMetrics(
    input: {interval: $interval, account_id: $account_id, username: $username}
  ) {
    assets_per_category
    assets_per_day
    findings_per_day
  }
}
`;

export const getAssetCountByCategoryQuery = gql`
  query MyQuery($account_id: String = "", $username: String = "") {
    asset_intelligence_v1_GetAssetCountByCategory(
      input: { account_id: $account_id, username: $username }
    ) {
      source_count
      category_count
    }
  }
`;


export const createGlobalRelationMut = gql`
mutation MyMutation($account_id: String = "", $depended_plugin_ids: [String] = "", $description: String = "", $end_node_ids: [String] = "", $end_node_labels: [String] = "", $end_where: api_asset_intelligence_v1_WhereClause_Input = {}, $props: String = "", $reverse_relation: Boolean = false, $start_node_ids: [String] = "", $start_node_labels: [String] = "", $start_where: api_asset_intelligence_v1_WhereClause_Input = {}, $type: String = "", $username: String = "") {
  asset_intelligence_v1_CreateGlobalRelation(
    input: {account_id: $account_id, description: $description, depended_plugin_ids: $depended_plugin_ids, end_node_ids: $end_node_ids, end_node_labels: $end_node_labels, end_where: $end_where, props: $props, reverse_relation: $reverse_relation, start_node_ids: $start_node_ids, start_node_labels: $start_node_labels, start_where: $start_where, type: $type, username: $username}
  ) {
    relation {
      account_id
      created_at
      depended_plugin_ids
      description
      end_asset_ids
      end_asset_tags
      end_asset_where {
        conditions {
          composite_condition {
            logical_operator
          }
          next_logical_operator
          simple_condition {
            operator
            property
            value
          }
        }
      }
      global_relation_id
      props
      reverse_relation
      start_asset_ids
      start_asset_tags
      type
      updated_at
      username
    }
  }
}
`