import {
  createUserMut,
  createGroupMut,
  addUserToGroupMut,
  removeUserFromGroupMut,
  deleteUserMut,
  deleteGroupMut,
  deletePolicyMut,
  createPolicyMut,
  addPolicyToGroupMut,
  removePolicyFromGroupMut,
  updateGroupMut,
  updateUserMut,
  updatePolicyMut,
  createAccessKeyMut,
  updateAccessKeyMut,
  deleteAccessKeyMut,
  users,
  groupsQueryQuery,
  groupsQuery,
  policiesQuery,
  getGroupQuery,
  readUserQuery,
  readPolicyQuery,
  usersQuery,
  dashboardQuery,
  iamQuery,
  setupSSOMut,
  updateSSOMut,
  deleteSSOMut,
  readSSOQuery,
  readAllPermissionsQuery,
  userByLastEvaluatedUserQuery,
  listUserGroupsQuery,
  listUserKeysQuery,
  listGroupUsersQuery,
  listGroupPoliciesQuery,
  groupsByLastEvalutaredGroupQuery,
  policiesByLastEvalutaedPolicyQuery,
  listUserKeysByLastEvaluatedKeyQuery,
  acceptInviteMut,
  requestAuthTransferMut,
  getCompanyStationQuery,
  getTranserAuthQuery,
  getAliasQuery,
  createAliasMut,
  updateAliasMut,
  deleteAliasMut,
  getSSOConfigQuery,
} from "../graphql/iam";
import { sendQueryRequest, sendMutationRequest } from "./apollo-client";

export const iamDashboardDataRequest = (account_id) => {
  return sendQueryRequest({
    query: dashboardQuery,
    variables: { account_id: account_id },
    fetchPolicy: "network-only",
  });
};

export const getUsers = (account_id) => {
  return sendQueryRequest({
    query: usersQuery,
    variables: { account_id: account_id },
    fetchPolicy: "network-only",
  });
};

export const getUserByEvaluatedUser = (account_id, last_evaluated_user) => {
  return sendQueryRequest({
    query: userByLastEvaluatedUserQuery,
    variables: {
      account_id: account_id,
      last_evaluated_user: last_evaluated_user,
    },
    fetchPolicy: "network-only",
  });
};

export const createUser = (input) => {
  return sendMutationRequest({ mutation: createUserMut, variables: input });
};

export const getGroups = (account_id) => {
  return sendQueryRequest({
    query: groupsQuery,
    variables: { account_id: account_id },
    fetchPolicy: "network-only",
  });
};

export const getGroupsByLastEvaulatedGroup = (
  account_id,
  last_evaluated_group
) => {
  return sendQueryRequest({
    query: groupsByLastEvalutaredGroupQuery,
    variables: {
      account_id: account_id,
      last_evaluated_group: last_evaluated_group,
    },
    fetchPolicy: "network-only",
  });
};

export const createGroup = (input) => {
  return sendMutationRequest({ mutation: createGroupMut, variables: input });
};

export const addUserToGroupRequest = (account_id, group_name, username) => {
  return sendMutationRequest({
    mutation: addUserToGroupMut,
    variables: {
      account_id: account_id,
      group_name: group_name,
      username: username,
    },
  });
};

export const removeUserFromGroupRequest = (
  account_id,
  group_name,
  username
) => {
  return sendMutationRequest({
    mutation: removeUserFromGroupMut,
    variables: {
      account_id: account_id,
      group_name: group_name,
      username: username,
    },
  });
};

export const deleteUserRequest = (account_id, username) => {
  return sendMutationRequest({
    mutation: deleteUserMut,
    variables: { account_id: account_id, username: username },
  });
};

export const deleteGroupRequest = (account_id, group_name) => {
  return sendMutationRequest({
    mutation: deleteGroupMut,
    variables: {
      account_id: account_id,
      group_name: group_name,
    },
  });
};

export const getPolicies = (account_id) => {
  return sendQueryRequest({
    query: policiesQuery,
    variables: { account_id: account_id },
    fetchPolicy: "network-only",
  });
};

export const getPoliciesByLastEvaluatedPolicy = (
  account_id,
  last_evaluated_policy
) => {
  return sendQueryRequest({
    query: policiesByLastEvalutaedPolicyQuery,
    variables: {
      account_id: account_id,
      last_evaluated_policy,
    },
    fetchPolicy: "network-only",
  });
};

export const createPolicyRequest = (
  account_id,
  policy_name,
  description,
  permissions
) => {
  return sendMutationRequest({
    mutation: createPolicyMut,
    variables: {
      account_id: account_id,
      policy_name: policy_name,
      description: description,
      permissions: permissions,
    },
  });
};

export const deletePolicyRequest = (account_id, policy_name) => {
  return sendMutationRequest({
    mutation: deletePolicyMut,
    variables: {
      account_id: account_id,
      policy_name: policy_name,
    },
  });
};

export const addPolicyToGroupRequest = (
  account_id,
  group_name,
  policy_name
) => {
  return sendMutationRequest({
    mutation: addPolicyToGroupMut,
    variables: {
      account_id: account_id,
      group_name: group_name,
      policy_name: policy_name,
    },
  });
};

export const removePolicyFromGroupRequest = (
  account_id,
  group_name,
  policy_name
) => {
  return sendMutationRequest({
    mutation: removePolicyFromGroupMut,
    variables: {
      account_id: account_id,
      group_name: group_name,
      policy_name: policy_name,
    },
  });
};

export const iamQueryRequest = (input) => {
  return sendQueryRequest({
    query: iamQuery,
    variables: input,
    fetchPolicy: "network-only",
  });
};

export const getGroupRequest = (account_id, group_name) => {
  return sendQueryRequest({
    query: getGroupQuery,
    variables: { account_id: account_id, group_name: group_name },
  });
};

export const updateGroupRequest = (input) => {
  return sendMutationRequest({ mutation: updateGroupMut, variables: input });
};

export const readUserRequest = (account_id, username, with_permissions) => {
  return sendQueryRequest({
    query: readUserQuery,
    variables: {
      account_id: account_id,
      username: username,
      with_permissions: with_permissions,
    },
  });
};

export const updateUserRequest = (input) => {
  return sendMutationRequest({ mutation: updateUserMut, variables: input });
};

export const readPolicyRequest = (account_id, policy_name) => {
  return sendQueryRequest({
    query: readPolicyQuery,
    variables: { account_id: account_id, policy_name: policy_name },
  });
};

export const updatePolicyRequest = (input) => {
  return sendMutationRequest({ mutation: updatePolicyMut, variables: input });
};

export const createAccessKeyRequest = (account_id, username) => {
  return sendMutationRequest({
    mutation: createAccessKeyMut,
    variables: { account_id: account_id, username: username },
  });
};

export const updateAccessKeyRequest = (input) => {
  return sendMutationRequest({
    mutation: updateAccessKeyMut,
    variables: input,
  });
};

export const deleteAccessKeyRequest = (access_key, account_id, username) => {
  return sendMutationRequest({
    mutation: deleteAccessKeyMut,
    variables: {
      access_key: access_key,
      account_id: account_id,
      username: username,
    },
  });
};

export const setupSSORequest = (
  account_id,
  client_id,
  client_secret,
  type,
  tenant_id
) => {
  return sendMutationRequest({
    mutation: setupSSOMut,
    variables: {
      account_id: account_id,
      client_id: client_id,
      client_secret: client_secret,
      type: type,
      tenant_id: tenant_id,
    },
  });
};

export const updateSSORequest = (
  account_id,
  client_id,
  client_secret,
  type
) => {
  return sendMutationRequest({
    mutation: updateSSOMut,
    variables: {
      account_id: account_id,
      client_id: client_id,
      client_secret: client_secret,
      type: type,
    },
  });
};

export const deleteSSORequest = (account_id) => {
  return sendMutationRequest({
    mutation: deleteSSOMut,
    variables: { account_id: account_id },
  });
};

export const readSSORequest = (account_id) => {
  return sendQueryRequest({
    query: readSSOQuery,
    variables: { account_alias: account_id },
    fetchPolicy: "network-only",
  });
};

export const getSSOConfigRequest = (account_id, username) => {
  return sendQueryRequest({
    query: getSSOConfigQuery,
    variables: { account_id: account_id, username: username },
    fetchPolicy: "network-only",
  });
};

export const readAllPermissionsRequest = (variables) => {
  return sendQueryRequest({
    query: readAllPermissionsQuery,
    variables: variables,
  });
};

export const listUserGroups = (account_id, username) => {
  return sendQueryRequest({
    query: listUserGroupsQuery,
    variables: {
      account_id: account_id,
      username: username,
    },
    fetchPolicy: "network-only",
  });
};

export const listUserKeys = (account_id, username) => {
  return sendQueryRequest({
    query: listUserKeysQuery,
    variables: {
      account_id: account_id,
      username: username,
    },
    fetchPolicy: "network-only",
  });
};

export const listUserKeysByLastEvaluatedKey = (
  account_id,
  username,
  last_evaluated_key
) => {
  return sendQueryRequest({
    query: listUserKeysByLastEvaluatedKeyQuery,
    variables: {
      account_id: account_id,
      username: username,
      last_evaluated_key: last_evaluated_key,
    },
    fetchPolicy: "network-only",
  });
};

export const listGroupUsers = (account_id, group_name) => {
  return sendQueryRequest({
    query: listGroupUsersQuery,
    variables: {
      account_id: account_id,
      group_name: group_name,
    },
    fetchPolicy: "network-only",
  });
};

export const listGroupPolicies = (account_id, group_name) => {
  return sendQueryRequest({
    query: listGroupPoliciesQuery,
    variables: {
      account_id: account_id,
      group_name: group_name,
    },
    fetchPolicy: "network-only",
  });
};

export const acceptInvite = (variables) =>
  sendMutationRequest({
    mutation: acceptInviteMut,
    variables: variables,
  });

export const getCompanyStation = (variables) =>
  sendQueryRequest({
    query: getCompanyStationQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const requestAuthTransfer = (variables) =>
  sendMutationRequest({
    mutation: requestAuthTransferMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getTranserAuth = (variables) =>
  sendQueryRequest({
    query: getTranserAuthQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const getAlias = (variables) =>
  sendQueryRequest({
    query: getAliasQuery,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const createAlias = (variables) =>
  sendMutationRequest({
    mutation: createAliasMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const updateAlias = (variables) =>
  sendMutationRequest({
    mutation: updateAliasMut,
    variables: variables,
    fetchPolicy: "network-only",
  });

export const deleteAlias = (variables) =>
  sendMutationRequest({
    mutation: deleteAliasMut,
    variables: variables,
    fetchPolicy: "network-only",
  });
